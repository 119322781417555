.POItemForm {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1em;
  grid-template-rows: auto;
  grid-template-areas:
    "name quantity  price control"
    ".    .         total control";
}

.POItemForm .Input,
.POItemForm .Readout {
  margin: 0px;
}

.POItemForm .Readout > .Field {
  white-space: initial;
}

.POItemForm > .Name {
  grid-area: name;
}

.POItemForm > .Quantity {
  grid-area: quantity;
}

.POItemForm > .Price {
  grid-area: price;
}

.POItemForm > .Total {
  grid-area: total;
}

.POItemForm > .Control {
  grid-area: control;
  align-self: center;
}

.POItemForm > .Total > .Field {
  color: green;
  opacity: 0.75;
}

.Section[data-size="xsmall"] {
  .POItemForm {
    grid-template-columns: 1fr 1fr 1em;
    grid-template-areas:
      "name     name    control"
      "quantity price   control"
      ".        total   control";
  }
}

.Section[data-size="small"] {
  .POItemForm {
    grid-template-columns: 1fr 1fr 1em;
    grid-template-areas:
      "name     name    control"
      "quantity price   control"
      ".        total   control";
    }
}



.Section[data-size="medium"] {
  .POItemForm {
    grid-template-columns: 2fr 1fr 1fr 1em;
    grid-template-areas:
      "name quantity  price control"
      "name    .      total control";
    }
}


.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .POItemForm {
    grid-template-columns: 3fr 1fr 1fr 1fr 1em;
    grid-template-areas:
    "name  quantity price total control"
  }
}
