.ProductItemForm {
  position: relative;
  display: grid;
  grid-template-columns: 2em 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
  "icon name quantity  price"
  "icon .    .         total";
}

.ProductItemForm .Input,
.ProductItemForm .Readout {
  margin: 0px;
}

.ProductItemForm > .Icon {
  align-self: center;
  grid-area: icon;
  text-align: center;
}

.ProductItemForm > .Icon > .Icon {
  max-width: 2em;
  max-height: 2em;
}

.ProductItemForm > .Name {
  grid-area: name;
}

.ProductItemForm > .Quantity {
  grid-area: quantity;
}

.ProductItemForm > .Price {
  grid-area: price;
}

.ProductItemForm > .Total {
  grid-area: total;
}

.ProductItemForm > .Control {
  grid-area: control;
  align-self: center;
}

.Section[data-size="xsmall"] {
  .ProductItemForm {
    grid-template-columns: 2em 1fr 2em 1fr;
    grid-template-areas:
      "icon      name      name  name"
      "price     quantity  total total";
  }
}

.Section[data-size="small"] {
  .ProductItemForm {
    grid-template-columns: 2em 1fr 1fr 1fr;
    grid-template-areas:
      "icon      name      name  name "
      "icon      price  quantity total";
  }
}



.Section[data-size="medium"] {
  .ProductItemForm {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas:
      "icon name     name  name"
      "icon quantity price total";
    }
}


.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .ProductItemForm {
    grid-template-columns: 2em 3fr 1fr 1fr 1fr;
    grid-template-areas:
    "icon name  quantity price total"
  }
}
