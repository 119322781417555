.TopMenu {
  position: fixed;
  display: flex;
  justify-content: space-between;
  color: white;
  width: 100%;
  height: 3em;
  top: 0;
  left: 0;
  z-index: 100;
  // background-color: #003755;
  background-color: black;
  box-sizing: border-box;
  padding: 0 1em;
  align-items: center;
}

.TopMenu[data-expanded="false"] {
  max-width: 0px;
  overflow: hidden;
}

.TopMenu > .Content {
  /* background-color: #333; */
  /* background-color: #1f2656; */
  background-color: #003755;
  color: white;
  box-shadow: 0 2px 6px -6px black;
  position: relative;
  display: grid;
  grid-template-columns: 3em 1fr 3em;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas: "Left Center Right";
  align-items: center;
  padding: 0.75em 1em;
}

.Frame[data-stage="dev"] .TopMenu {
  background-color: darkred;
}

.Frame[data-stage="local"] .TopMenu {
  background-color: #232a54;
}

.TopMenu > .Content > .Left {
  grid-area: Left;
}

.TopMenu > .Content > .Center {
  grid-area: Center;
  text-align: center;
}

.TopMenu > .Content > .Right {
  grid-area: Right;
  text-align: right;
}


.TopMenu > .Content > .Left .PlainButton {
  display: block;
}
.TopMenu .Left img {
  display: block;
  height: 1.2em;
  width: auto;
  padding-top: 0.1em;
}

.TopMenu > .Content > .Right .PlainButton {
  /* display: block; */
  background: #005590;
  border-radius: 50%;
  width: 2em;
  height: 2em;
  line-height: 2em;
  box-sizing: border-box;
}

.TopMenu {
  .AccountDetails {
    color: #333;
    summary {
      color: white;
    }
    .DetailsList {
      text-align: left;
      right: 0;
      left: unset;
    }
  }

  .AccountDetails[open] {
    summary {
      color: orange;
      opacity: 1;
    }
  }

  .Signin {
    width: auto;
  }

}
