.UploadPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "Title   Title"
    "Files   Files"
    "Cancel   Ok";
}

.UploadPopup > .Title {
  grid-area: Title;
  color: #333;
  font-size: 1.5em;
}

.UploadPopup > .Files {
  grid-area: Files;
}

.UploadPopup > form {
  width: 100%;
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "KeyTitle   Key"
    "ContentTitle   ContentType"
    "TagsTitle   Tags"
    "FileTitle   File"
    "Submit   Submit";
}

.UploadPopup > form > .KeyTitle {
  grid-area: KeyTitle;
  text-align: right;
}

.UploadPopup > form > .Key {
  grid-area: Key;
}

.UploadPopup > form > .ContentTitle {
  grid-area: ContentTitle;
  text-align: right;
}

.UploadPopup > form > .ContentType {
  grid-area: ContentType;
}

.UploadPopup > form > .TagsTitle {
  grid-area: TagsTitle;
  text-align: right;
}

.UploadPopup > form > .Tags {
  grid-area: Tags;
}

.UploadPopup > form > .FileTitle {
  grid-area: FileTitle;
  text-align: right;
}

.UploadPopup > form > .File {
  grid-area: File;
}

.UploadPopup > form > .Submit {
  grid-area: Submit;
}

.Upload > .Ok {
  grid-area: Ok;
}


.Upload > .Cancel {
  grid-area: Cancel;
}
