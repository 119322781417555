.Separator {
  height: 1px;
  width: 100%;
  background-color: rgba(0,0,0,0.05);
  margin-top: 1em;
  margin-bottom: 1em;
}

.Separator[data-theme="white"] {
  background-color: white;
}

.Separator[data-theme="transparent"] {
  background-color: transparent;
}

.Separator[data-display="full"] {
  position: relative;
  width: calc(100% + 2em);
  left:-1em;
  height: 2px;
}

.Separator[data-collapsed="tiny"] {
  margin-top: 0.25em;
  margin-bottom: 0.25em;
}

.Separator[data-collapsed="semi"] {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.Separator[data-collapsed="true"] {
  margin-top: 0px;
  margin-bottom: 0px;
}
