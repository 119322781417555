.PageHeaderOutline {
  display: grid;
  width: 100%;
  box-sizing: border-box;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
  grid-template-areas:
    "top"
    "middle";
}

.PageHeaderOutline[data-bottom="true"] {
  grid-template-areas:
    "top"
    "middle"
    "bottom";
}

.PageHeaderOutline > .HeaderTop {
  grid-area: top;
  width: 100%;
  text-align: left;
}

.PageHeaderOutline > .HeaderMiddle {
  display: grid;
  grid-area: middle;
  grid-template-columns: 3fr 8em;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  align-items: center;
}

.PageHeaderOutline > .HeaderBottom {
  grid-area: bottom;
  text-align: left;
  display: flex;
  gap: 1em;
  align-items: center;
}

.PageHeaderOutline > .HeaderBottom .PlainButton {
  width: auto;
}

.PageHeaderOutline > .HeaderMiddle > .Title {
  /* color: #333; */
  font-size: 1.5em;
  font-weight: bold;
  text-align: left;
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

@media (min-width: 30.5em) {
  .PageHeaderOutline {
    padding:0px;
  }
}
