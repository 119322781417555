.QueryPaginatePresentation {

}

.QueryPaginatePresentation[data-status="loading"] {
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(100%);
}

.QueryPaginatePresentation[data-status="loading"] {
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(100%);
}
