.Page.ProductPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.ProductPage > div {
  max-width: 65em;
  width: 100%;
}

.ProductPage .Content .Item {
  border-top: 1px solid transparent;
  padding-top: 1em;
  padding-bottom: 1em;
}

.ProductPage .Content .Item:first-child {
  padding-top: 0em;
  border-top-width: 0px;
}


.ProductPage > .Section .LocationItemLineHeader {
  padding-bottom: 0.75em;
}

.ProductPage > .Section .LocationItemLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}

.ProductPage > .TableSearchSection.Components {
  .Price,
  .Quantity,
  .Total {
    width: 5em;
    text-align: right;
  }
  .Td4.Total::before {
    content: "$";
    right: 0.1em;
    position: relative;
  }
}

.ProductPage > .TableSearchSection.Processes {

  .Price,
  .Quantity,
  .Total {
    width: 5em;
    text-align: right;
  }

  .Td3.Total::before {
    content: "$";
    right: 0.1em;
    position: relative;
  }
}


.ProductPage > .TableSearchSection.Inventory {
  .Expected {
    width: 5em;
    text-align: right;
  }
  .Quantity {
    width: 5em;
    padding-right: 1.5em;
    text-align: right;
  }
}

.ProductPage > .TableSearchSection.Vendors {
  .Lead_Time {
    width: 5em;
    text-align: right;
  }
  .Cost {
    width: 5em;
    padding-right: 1.5em;
    text-align: right;
  }
}
