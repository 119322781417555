.FileLayout {
  position: relative;
}

.FileLayout > .Edit {
  position: absolute;
  top: 0;
  right: 0;
  background-color: #ffffff77;
  padding: 0.5em;
  border-radius: 50%;
  opacity: 0;
  cursor: pointer;
}

.FileLayout:hover > .Edit {
  transition: opacity 0.5s; 
  opacity: 1;
}

.FileLayout > .Content {
  width: 100%;
  max-width: 10em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.25em;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas:
    "Icon Icon Icon"
    "Name Name Name"
    "Info Info Info";
  border: 2px dashed transparent;
  box-sizing: border-box;
  padding: 0.5em;
  border-radius: 5px;
}

.FileLayout > .Content > .Icon {
  position: relative;
  grid-area: Icon;
  display: inline-block;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 3em;
  padding-bottom: 0.25em;
  object-fit: contain;

}

.FileLayout > .Content > .Name {
  grid-area: Name;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FileLayout > .Content > .Type {
  grid-area: Type;
  text-align: left;
  font-size: 0.75em;
  opacity: 0.5;
}

.FileLayout > .Content > .Info {
  grid-area: Info;
  text-align: center;
}
