.HeaderLine {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "First";
  grid-gap: 0.1em;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
  font-weight: bold;
}

.HeaderLine[data-faint="true"] {
  color: silver;
}

.HeaderLine[data-hide="true"] {
  display: none;
}

.HeaderLine  > .Header:first-child  {
  grid-area: First;
  text-align: left;
}

.HeaderLine > .Header {
  display: none;
}

.Section[data-size="small"],
.Section[data-size="xsmall"] {

  .HeaderLine[data-headers="2"],
  .HeaderLine[data-headers="3"],
  .HeaderLine[data-headers="4"],
  .HeaderLine[data-headers="5"],
  .HeaderLine[data-headers="6"]  {
    grid-template-columns: 2fr 1fr;
  }

  .HeaderLine  > .Header:first-child,
  .HeaderLine  > .Header:last-child  {
    display: block;
  }
}

.Section[data-size="medium"] {

  .HeaderLine[data-headers="2"] {
    grid-template-columns: 2fr 1fr;
  }

  .HeaderLine[data-headers="3"],
  .HeaderLine[data-headers="4"],
  .HeaderLine[data-headers="5"],
  .HeaderLine[data-headers="6"] {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .HeaderLine  > .Header:first-child,
  .HeaderLine  > .Header:nth-last-child(2),
  .HeaderLine  > .Header:last-child  {
    display: block;
  }
}

.Section[data-size="large"] {

  .HeaderLine[data-headers="3"] {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .HeaderLine[data-headers="4"],
  .HeaderLine[data-headers="5"],
  .HeaderLine[data-headers="6"]{
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }

  .HeaderLine  > .Header:first-child,
  .HeaderLine  > .Header:nth-last-child(3),
  .HeaderLine  > .Header:nth-last-child(2),
  .HeaderLine  > .Header:last-child  {
    display: block;
  }
}

.Section[data-size="xlarge"] {

  .HeaderLine[data-headers="3"] {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .HeaderLine[data-headers="4"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }

  .HeaderLine[data-headers="5"],
  .HeaderLine[data-headers="6"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .HeaderLine  > .Header:first-child,
  .HeaderLine  > .Header:nth-last-child(4),
  .HeaderLine  > .Header:nth-last-child(3),
  .HeaderLine  > .Header:nth-last-child(2),
  .HeaderLine  > .Header:last-child  {
    display: block;
  }
}

.Section[data-size="xxlarge"] {

  .HeaderLine[data-headers="3"] {
    grid-template-columns: 2fr 1fr 1fr;
  }

  .HeaderLine[data-headers="4"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
  }

  .HeaderLine[data-headers="5"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
  }

  .HeaderLine[data-headers="6"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
  }

  .HeaderLine  > .Header:first-child,
  .HeaderLine  > .Header:nth-last-child(5),
  .HeaderLine  > .Header:nth-last-child(4),
  .HeaderLine  > .Header:nth-last-child(3),
  .HeaderLine  > .Header:nth-last-child(2),
  .HeaderLine  > .Header:last-child  {
    display: block;
  }
}
