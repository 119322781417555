.Page.ProductsPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.ProductsPage > div {
  max-width: 65em;
  width: 100%;
}

.ProductsPage .TableSearchSection {

  .ID {
    width: 3em;
    text-align: right;
  }

  .Type {
    width: 6em;
  }

  .SKU {
    width: 8em;
  }

  .Description {
    width: 1em;
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }
}
