.FilterLine {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 3fr 1fr;
  grid-auto-columns: 1fr;
  grid-template-rows: auto;
  grid-column-gap: 10px;
  grid-auto-flow: column;
  align-items: center;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-sizing: border-box;
  padding: 0.25em;
}
