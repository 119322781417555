.ActionVal {
  color: #006095;

  font-weight:normal;
}

.ActionVal[data-theme="red"] {
  color: #e6391a;
}

.ActionVal:hover {
  cursor: pointer;
  text-decoration: underline;
}
