.GridArea {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5em;
}

.Section[data-size="xsmall"] {
  .GridArea {
    grid-template-columns: repeat(1, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(2, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Section[data-size="small"] {
  .GridArea {
    grid-template-columns: repeat(2, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(2, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Section[data-size="medium"] {
  .GridArea {
    grid-template-columns: repeat(3, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(4, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(6, 1fr);
  }
}

.Section[data-size="large"] {
  .GridArea {
    grid-template-columns: repeat(4, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(5, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(8, 1fr);
  }
}

.Section[data-size="xlarge"] {
  .GridArea {
    grid-template-columns: repeat(5, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(6, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(10, 1fr);
  }
}

.Section[data-size="xxlarge"] {
  .GridArea {
    grid-template-columns: repeat(6, 1fr);
  }
  .GridArea[data-fit="med"] {
    grid-template-columns: repeat(8, 1fr);
  }
  .GridArea[data-fit="double"] {
    grid-template-columns: repeat(12, 1fr);
  }
}
