.Page.TransfersPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.TransfersPage > div {
  max-width: 65em;
  width: 100%;
}

.TransfersPage > .Section .TransferLineHeader {
  padding-bottom: 0.75em;
}

.TransfersPage > .Section .TransferLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
