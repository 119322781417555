.PaymentPopup {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 30em;
  height: 100%;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
}

.PaymentPopup > .Content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.PaymentPopup > .Content > .Section {
  text-align: center;
}


.PaymentPopup > .Content > .Section > .Content > .Title {
  color: #333;
  font-size: 1.5em;
}

.PaymentPopup > .Content > .Section > .Content > .Message {
  color: #333;
}
