.ItemOptionLine {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 2em 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "Check First Last";
  grid-gap: 0.5em;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
}

.ItemOptionLine:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.ItemOptionLine[data-selected="true"]  {
  background-color: #00609515;
}

.ItemOptionLine  > .Check  {
  // color: #ddd;
  color: #00609577;
  text-align: left;
  grid-area: Check;
}

.ItemOptionLine[data-selected="true"]  > .Check  {
  color: #006095;
}

.ItemOptionLine  > .Value2  {
  grid-area: Second;
}

.ItemOptionLine  > .Value3  {
  grid-area: Third;
}

.ItemOptionLine  > .Value4  {
  grid-area: Fourth;
}

.ItemOptionLine  > .Value5  {
  grid-area: Fifth;
}

.ItemOptionLine  > .Value6  {
  grid-area: Sixth;
}

.ItemOptionLine  > .Value:last-child  {
  grid-area: Last;
  text-align: right;
}

.ItemOptionLine  > .Value:nth-child(2)  {
  grid-area: First;
  text-align: left;
}

.ItemOptionLine[data-values="1"]  {
  grid-template-columns: 2em 1fr;
  grid-template-areas: "Check First";
}

.Section[data-size="small"],
.Section[data-size="xsmall"] {

  .ItemOptionLine[data-values="2"]  {
    grid-template-columns: 2em 1fr;
    grid-template-areas:
    "Check First"
    "Check Last";
    > .Value2 {
      text-align: left;
    }
  }


  .ItemOptionLine[data-values="3"]  {
    grid-template-areas:
    "Check First  Last"
    "Check Second .";
    > .Value2 {
      text-align: left;
    }
  }
  .ItemOptionLine[data-values="4"]  {
    grid-template-areas:
    "Check First  Last"
    "Check Second Third";
    > .Value2 {
      text-align: left;
    }

    > .Value3 {
      text-align: right;
    }
  }
  .ItemOptionLine[data-values="5"] {
    grid-template-areas:
    "Check First  Last"
    "Check Second Fourth"
    "Check Third  .";
    > .Value2,
    > .Value3 {
      text-align: left;
    }

    > .Value4 {
      text-align: right;
    }
  }
  .ItemOptionLine[data-values="6"]  {
    grid-template-areas:
    "Check First  Last"
    "Check Second Fourth"
    "Check Third  Fifth";
    > .Value2,
    > .Value3 {
      text-align: left;
    }

    > .Value4,
    > .Value5 {
      text-align: right;
    }
  }
}

.Section[data-size="medium"] {

  .ItemOptionLine[data-values="3"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First Second Last";
  }

  .ItemOptionLine[data-values="4"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First  Third Last"
                         "Check Second Third Last";

    > .Value2 {
      text-align: left;
    }
  }

  .ItemOptionLine[data-values="5"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First  Fourth Last"
                         "Check Second Fourth Last"
                         "Check Third  Fourth Last";
   > .Value2,
   > .Value3 {
     text-align: left;
   }
  }

  .ItemOptionLine[data-values="6"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First  Fifth Last"
                         "Check Second Fifth Fourth"
                         "Check Third  Fifth .";
     > .Value2,
     > .Value3 {
       text-align: left;
     }

     > .Value4 {
       text-align: right;
     }
  }

}

.Section[data-size="large"] {

  .ItemOptionLine[data-values="3"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First Second Last";
  }

  .ItemOptionLine[data-values="4"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Last";
  }

  .ItemOptionLine[data-values="5"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr;
    grid-template-areas: "Check First  Third Fourth Last"
                         "Check Second Third Fourth Last";
    > .Value2{
      text-align: left;
    }
  }

  .ItemOptionLine[data-values="6"]{
    grid-template-columns: 2em 2fr 1fr 1fr 1fr;
    grid-template-areas: "Check First  Fourth Fifth Last"
                         "Check Second Fourth Fifth Last"
                         "Check Third  Fourth Fifth Last";
    > .Value2{
      text-align: left;
    }
    > .Value3{
      text-align: left;
    }
  }

}

.Section[data-size="xlarge"] {

  .ItemOptionLine[data-values="3"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First Second Last";
  }

  .ItemOptionLine[data-values="4"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Last";
  }

  .ItemOptionLine[data-values="5"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Fourth Last";
  }

  .ItemOptionLine[data-values="6"]{
    grid-template-columns: 2em 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "Check First  Third Fourth Fifth Last"
                         "Check Second Third Fourth Fifth Last";
    > .Value2{
      text-align: left;
    }
  }

}

.Section[data-size="xxlarge"] {

  .ItemOptionLine[data-values="3"] {
    grid-template-columns: 2em 2fr 1fr 1fr;
    grid-template-areas: "Check First Second Last";
  }

  .ItemOptionLine[data-values="4"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Last";
  }

  .ItemOptionLine[data-values="5"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Fourth Last";
  }

  .ItemOptionLine[data-values="6"] {
    grid-template-columns: 2em 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "Check First Second Third Fourth Fifth Last";
  }

}
