.ControlLine {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns:  2em 1fr 0.5em;
  grid-template-rows: auto;
  grid-template-areas: "Image Content Settings";
  grid-gap: 1em;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
}

.ControlLine[data-clickable="true"]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}


.ControlLine.Line  > .Image  {
  grid-area: Image;
  align-self: center;
  text-align: center;
}

.ControlLine  > .Image > img {
  object-fit: contain;
  max-width: 100%;
}

.ControlLine  > .Content  {
  grid-area: Content;
}

.ControlLine  > .Settings  {
  grid-area: Settings;
}

.ControlLine[data-settable="false"] {
  grid-template-columns:  2em 1fr;
  grid-template-areas: "Image Content";

  > .Settings {
    display: none;
  }
}
