.InfoLayout {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-columns: 2fr 1fr;
  grid-template-rows: auto;
  grid-template-areas: "First Last";
  grid-gap: 0.5em;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
}

.InfoLayout[data-values="1"] {
  grid-template-columns: 1fr;
  grid-template-areas: "First";
}

.InfoLayout[data-clickable="true"]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.InfoLayout  > .Value2  {
  grid-area: Second;
}

.InfoLayout  > .Value3  {
  grid-area: Third;
}

.InfoLayout  > .Value4  {
  grid-area: Fourth;
}

.InfoLayout  > .Value5  {
  grid-area: Fifth;
}

.InfoLayout  > .Value6  {
  grid-area: Sixth;
}

.InfoLayout  > .Value:last-child  {
  grid-area: Last;
  text-align: right;
}

.InfoLayout  > .Value:first-child  {
  grid-area: First;
  text-align: left;
}

.Section[data-size="small"],
.Section[data-size="xsmall"] {

  .InfoLayout[data-values="3"]  {
    grid-template-areas:
    "First  Last"
    "Second .";
    > .Value2 {
      text-align: left;
    }
  }
  .InfoLayout[data-values="4"]  {
    grid-template-areas:
    "First  Last"
    "Second Third";
    > .Value2 {
      text-align: left;
    }
    > .Value3 {
      text-align: right;
    }
  }
  .InfoLayout[data-values="5"] {
    grid-template-areas:
    "First  Last"
    "Second Fourth"
    "Third  .";
    > .Value2,
    > .Value3 {
      text-align: left;
    }

    > .Value4 {
      text-align: right;
    }
  }
  .InfoLayout[data-values="6"]  {
    grid-template-areas:
    "First  Last"
    "Second Fourth"
    "Third  Fifth";
    > .Value2,
    > .Value3 {
      text-align: left;
    }

    > .Value4,
    > .Value5 {
      text-align: right;
    }
  }
}

.Section[data-size="medium"] {

  .InfoLayout[data-values="3"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First Second Last";
  }

  .InfoLayout[data-values="4"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First  Third Last"
                         "Second Third Last";

    > .Value2 {
      text-align: left;
    }
  }

  .InfoLayout[data-values="5"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First  Fourth Last"
                         "Second Fourth Last"
                         "Third  Fourth Last";
   > .Value2,
   > .Value3 {
     text-align: left;
   }
  }

  .InfoLayout[data-values="6"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First  Fifth Last"
                         "Second Fifth Fourth"
                         "Third  Fifth .";
     > .Value2,
     > .Value3 {
       text-align: left;
     }

     > .Value4 {
       text-align: right;
     }
  }

}

.Section[data-size="large"] {

  .InfoLayout[data-values="3"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First Second Last";
  }

  .InfoLayout[data-values="4"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Last";
  }

  .InfoLayout[data-values="5"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas: "First  Third Fourth Last"
                         "Second Third Fourth Last";
    > .Value2{
      text-align: left;
    }
  }

  .InfoLayout[data-values="6"]{
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas: "First  Fourth Fifth Last"
                         "Second Fourth Fifth Last"
                         "Third  Fourth Fifth Last";
    > .Value2{
      text-align: left;
    }
    > .Value3{
      text-align: left;
    }
  }

}

.Section[data-size="xlarge"] {

  .InfoLayout[data-values="3"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First Second Last";
  }

  .InfoLayout[data-values="4"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Last";
  }

  .InfoLayout[data-values="5"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Fourth Last";
  }

  .InfoLayout[data-values="6"]{
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "First  Third Fourth Fifth Last"
                         "Second Third Fourth Fifth Last";
    > .Value2{
      text-align: left;
    }
  }

}

.Section[data-size="xxlarge"] {

  .InfoLayout[data-values="3"] {
    grid-template-columns: 2fr 1fr 1fr;
    grid-template-areas: "First Second Last";
  }

  .InfoLayout[data-values="4"] {
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Last";
  }

  .InfoLayout[data-values="5"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Fourth Last";
  }

  .InfoLayout[data-values="6"] {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas: "First Second Third Fourth Fifth Last";
  }

}
