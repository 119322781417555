.Form {
  width: 100%;
  display: grid;
  grid-gap: 1.25em 2em;
  box-sizing: border-box;
}

.Form  .Input,
.Form  .DropControl,
.Form  .Readout {
  margin: 0px;
}

.Form[data-action="delete"] > * {
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(100%);
}

.Form[data-action="delete"] > *:last-child {
  pointer-events: all;
  filter: none;
  opacity: 1;
}
