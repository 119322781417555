.SearchPage .SearchSection {
  .QueryOptionsPresentation {
    display: none;
  }
}

.SearchPage .SearchSection {
  max-width: 60em;
  z-index: 14;
}

.SearchPage .SearchSection[data-expanded="true"] {
  .FilterButton {
    display: block;
    // background-color: #006095;
    // color: white;
    color: #f98100;
  }
  .QueryOptionsPresentation {
    display: grid;
  }
}

.SearchPage > Results {
  min-width: 60em;
}
