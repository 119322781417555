.Page.VendorsPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.VendorsPage > div {
  max-width: 65em;
  width: 100%;
}

.VendorsPage > .Section .VendorLineHeader {
  padding-bottom: 0.75em;
}

.VendorsPage > .Section .VendorLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
