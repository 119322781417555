.FormPopup {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
}

.FormPopup > .Content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.FormPopup .Section {
  text-align: center;
}

.FormPopup .Title {
  margin: 0;
}

.FormPopup input[type="checkbox"] {
  -webkit-appearance: auto;
  margin-right: 1em;
}
