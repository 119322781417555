.QueryOptionsPresentation {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 1.5em;
}

.QueryOptionsPresentation .Filter .Options {
  max-height: 15em;
  overflow-y: scroll;
}

.Section[data-size="xsmall"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(1, 1fr);
  }
}

.Section[data-size="small"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(2, 1fr);
  }
}

.Section[data-size="medium"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(3, 1fr);
  }
}

.Section[data-size="large"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(4, 1fr);
  }
}

.Section[data-size="xlarge"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(5, 1fr);
  }
}

.Section[data-size="xxlarge"] {
  .QueryOptionsPresentation {
    grid-template-columns: repeat(6, 1fr);
  }
}
