.PaymentLineHeader {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #333;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "Name Name  Paid";
}

.PaymentLineHeader > div:first-child {
  text-align: left;
}

.PaymentLineHeader > div:last-child {
  text-align: right;
}

.PaymentLineHeader > .Name {
  grid-area: Name;
}

.PaymentLineHeader > .Type {
  grid-area: Type;
  display: none;
}

.PaymentLineHeader > .Amount {
  grid-area: Amount;
  text-align: right;
  display: none;
}

.PaymentLineHeader > .Paid {
  grid-area: Paid;
  text-align: right;
}

@media (min-width: 45.5em) {
  .PaymentLineHeader {
    grid-template-columns: 3fr 1fr 1fr 1fr;
    grid-template-areas:
    "Name Type Amount Paid"
  }
  .PaymentLineHeader > .Type {
    display: block;
  }

  .PaymentLineHeader > .Amount {
    display: block;
  }
}
