.SubVal {
  /* color: grey; */
  color: #888888;
  font-size: 1em;
}


.SubVal.Subract {
  /* color: grey; */
  color: #FF8888;
  font-size: 1em;
}

.SubVal.Subract::before {
  content: "-";
}
