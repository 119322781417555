.Page.BuildsPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.BuildsPage > * {
  max-width: 65em;
  width: 100%;
}

.BuildsPage {
  .Results {
    width: 100%;
  }
}


.BuildsPage table.Results {
  thead {
    z-index: 5;
  }

  .Controls {
    width: 1em;
    white-space: nowrap;
    text-align: center;
    font-size: 0.9em;
  }

  .Created {
    width: 8em;
  }

  .Status {
    text-align: left;
    width: 8em;
  }

  .Completed {
    width: 8em;
  }

  .Notes {
    text-align: center;
    width: 2em;
  }

  .Notes {
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }

  tr[data-creating="true"] td.Controls {
    opacity: 0.5;
    color: red;
  }
}
