.ModelSearchSection {
  margin: 1em 0;

  table.Card {
    box-shadow: none;
    margin-bottom: 0.5em;
    width: 100%;

    thead {
      box-shadow: inset 0px 0px 0px 1px rgba(0,0,0,0.07);
    }

    th {
      background-color: rgba(0,0,0,0.02);
      font-weight: normal;
      border: none;
    }

    td {
      border: none;
    }

    caption.Submessage {
      svg {
        // color: #ffda00;
        color: silver;
        font-size: 2em;
      }
      div {
        padding-top: 1em;
      }
      padding: 1em;
      caption-side: bottom;
    }
  }

  .QueryOptionsPresentation {
    display: none;
    margin-bottom: 0.5em;
  }

  .QueryPaginatePresentation {
    display: flex;
    width: 100%;
    justify-content: center;
  }

  table[data-empty="true"] {
    thead {
      //opacity: 0.5;
    }
  }

}



.ModelSearchSection[data-expanded="true"] {
  .QueryOptionsPresentation {
    display: grid;
  }
}
