.LocationItemForm {
  position: relative;
  display: grid;
  grid-template-columns: 2em 1fr 1fr 1fr 1em;
  grid-template-rows: auto;
  grid-template-areas:
    "icon name      name      name     control"
    "icon expected  received  sent     control"
    "icon quantity  quantity  quantity control";
}

.LocationItemForm .Input,
.LocationItemForm .Readout {
  margin: 0px;
}

.LocationItemForm > .Icon {
  text-align: center;
  align-self: center;
  grid-area: icon;
}

.LocationItemForm > .Icon > .Icon {
  max-width: 4em;
  max-height: 4em;
}

.LocationItemForm > .Name {
  grid-area: name;
}

.LocationItemForm > .Expected {
  grid-area: expected;
}

.LocationItemForm > .Received {
  grid-area: received;
}

.LocationItemForm > .Sent {
  grid-area: sent;
}

.LocationItemForm > .Quantity {
  grid-area: quantity;
}


.LocationItemForm > .Control {
  grid-area: control;
  align-self: center;
}

.Section[data-size="xsmall"],
.Section[data-size="small"] {
  .LocationItemForm {
    grid-template-columns: 4em 1fr 1fr;
    grid-template-areas:
      "icon name     name"
      "icon expected quantity";
  }
}

.Section[data-size="medium"],
.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .LocationItemForm {
    grid-template-columns: 4em 3fr 1fr 1fr;
    grid-template-areas:
    "icon name  expected quantity"
  }
}
