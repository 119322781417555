.FooterArea {
  display: flex;
  justify-content: right;
  align-items: center;
  gap: 1em;
}

.FooterArea > div:first-child {
  text-align: left;
}

.FooterArea > div.Left {
  margin-right: auto;
}

.FooterArea > .RectButton {
  min-width: 0em;
  max-width: 7em;
}

.FooterArea > .PlainButton {
  width: auto;
  min-width: 0em;
  max-width: 7em;
}
