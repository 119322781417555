.Page.InventoryPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.InventoryPage > div {
  max-width: 65em;
  width: 100%;
}


.InventoryPage > .TableSearchSection {
  .Quantity {
    width: 5em;
    text-align: right;
  }
}
