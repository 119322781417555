.Page.VendorPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.VendorPage > div {
  max-width: 65em;
  width: 100%;
}
