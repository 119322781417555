.FileForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Type"
    "Name"
    "Description"
    "PaidAt"
    "Amount";
}

.FileForm > .Type {
  grid-area: Type;
}

.FileForm > .Name {
  grid-area: Name;
}

.FileForm > .Description {
  grid-area: Description;
}

.FileForm > .PaidAt {
  grid-area: PaidAt;
}

.FileForm > .Amount {
  grid-area: Amount;
}
