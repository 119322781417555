.Page.OrderPage {
  max-width: 934px;
}

.OrderPage .Content .Item {
  border-top: 1px solid #eee;
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.OrderPage .Content .Item:first-child {
  border-top-width: 0px;
}
