.Row {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Row[data-align="space-between"] {
  justify-content: space-between;
}

.Row[data-align="right"] {
  justify-content: flex-end;
}

.Row > .Col {
  display: inline-block;
  box-sizing: border-box;
  flex-grow: 1;
  padding-left: 0.5em;
  padding-right:  0.5em;
}

.Row[data-align="space-between"] > .Col,
.Row[data-align="right"] > .Col  {
  flex-grow: unset;
}


.Row > .Col:first-child {
  padding-left: 0px;
}

.Row > .Col:last-child {
  padding-right: 0px;
}
