.Readout {
  font-size: 1em;
  position: relative;
  height: 3em;
  min-width: 6em;
  text-align: left;
  display: inline-block;
  /* border-bottom: 1px solid #eee; */
}

.Readout > .Field {
  position: relative;
  top:50%;
  transform: translateY(calc(-50% + 0.25em));
  display: block;
  border: none;
  padding: 0em;
  font-size: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.Readout > .Label {
  position: absolute;
  pointer-events: none;
  line-height: 1em;
  top: 0em;
  left: 0em;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}

.Readout[data-type="money"] > .Field {
  /* color: rgba(20,150,110,1); */
}


.Readout[data-clickable="true"] > .Field {
  cursor: pointer;
  /* color: #3f4eae; */
  /* color: #006fbb; */
  color: #006095;
}
