.TransferLine {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "name name  createdAt "
    "path path  createdAt"
    "type   .   cost"
    "status .   .";
}

.TransferLine > .Name {
  grid-area: name;
}

.TransferLine > .Type {
  grid-area: type;
  text-align: left;
}

.TransferLine > .Status {
  grid-area: status;
  text-align: left;
}

.TransferLine > .Path {
  grid-area: path;
  text-align: left;
  font-size: 0.75em;
  opacity: 0.5;
}

.TransferLine > .Cost {
  text-align: right;
  grid-area: cost;
  opacity: 0.75;
  color: green;
}

.TransferLine > .CreatedAt {
  grid-area: createdAt;
  text-align: right;
}

.TransferLine[data-type="po"] > .Type > .Highlight {
  border-color: limegreen;
  background-color: #90ee9014;
  color: darkgreen;
}

.TransferLine[data-type="move"] > .Type > .Highlight {
  border-color: #21bbeb;
  background-color: #7fd8f3;
}

.TransferLine[data-type="fulfillment"] > .Type > .Highlight {
  border-color: #bb0;
  background-color: yellow;
}

.TransferLine[data-type="return"] > .Type > .Highlight {
  border-color: #bb5c00;
  background-color: #fad417;
}

.TransferLine[data-type="discard"] > .Type > .Highlight {
  border-color: red;
  background-color: #ffc0cb30;
  color: firebrick;
}

.TransferLine[data-status="new"] > .Status > .Highlight {
  border-color: #6da4bb;
  background-color: #add8e62e;
  color: #3f3fa5;
}

.TransferLine[data-status="received"] > .Status > .Highlight {
  border-color: limegreen;
  background-color: #90ee9014;
  color: darkgreen;
}

.TransferLine[data-status="sent"] > .Status > .Highlight {
  border-color: #9e56bd;
  background-color: #3b016d12;
  color: #3a016b;
}

.TransferLine[data-status="canceled"] > .Status > .Highlight {
  border-color: red;
  background-color: pink;
  color: firebrick;
}



@media (min-width: 30.5em) {
  .TransferLine {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name  name  status createdAt"
      "path  path  .      createdAt"
      "type  .     .      cost";
  }
  .TransferLine > .Status {
    text-align: inherit;
  }
}


@media (min-width: 45.5em) {
  .TransferLine {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "name  name  type status createdAt"
    "path  path     .    .      cost";
  }
  .TransferLine > .Type {
      text-align: inherit;
  }
}

@media (min-width: 60.5em) {
  .TransferLine {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "name  name  type status cost createdAt"
    "path  path  .    .      .    . ";
  }
  .TransferLine > .Cost {
      text-align: inherit;
  }
}
