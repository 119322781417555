.Section {
  position: relative;
  /* display: flex;
  flex-flow: column; */
  text-align: left;
  gap: 1em;
  background: white;
  padding: 1.5em 0em;
  border-radius: 5px;
  box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.1);
}

.Section[data-theme="clear"] {
  background-color: transparent;
  -webkit-box-shadow: none;
	   -moz-box-shadow: none;
	        box-shadow: none;
  border: 1px solid #dddddd;
}

.Section[data-tab="true"] {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.Section[data-locked="true"] {
  opacity: 0.35;
  pointer-events: none;
  filter: grayscale(100%);
}

/* .Section > h4 {
  margin: 0;
}

.Section > table {

} */
