.Page.LocationsPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.LocationsPage > div {
  max-width: 65em;
  width: 100%;
}


.LocationsPage .TableSearchSection {
  // .Name {
  //   width: 100%;
  // }
  .Country {
    select {
      width: 8em;
    }
  }
}
