.ProductForm {
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "type image"
    "name name"
    "sku sku"
    "code code"
    "msrp msrp"
    "rate rate"
    "time time"
    "description description";
}

.ProductForm > .Type {
  grid-area: type;
  text-align: left;
}

.ProductForm > .Image {
  grid-area: image;
  text-align: center;
  align-self: center;
}

.ProductForm > .Name {
  grid-area: name;
}

.ProductForm > .Sku {
  grid-area: sku;
}

.ProductForm > .Code {
  grid-area: code;
}

.ProductForm > .MSRP {
  grid-area: msrp;
}

.ProductForm > .DepletionRate {
  grid-area: rate;
}

.ProductForm > .LeadTime {
  grid-area: time;
}

.ProductForm > .Description {
  grid-area: description;
}

.ProductForm > .Image > .Icon {
  height: 2em;
  font-size: 2em;
}

.Section[data-size="medium"],
.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .ProductForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "type         image"
      "name         image"
      "sku          image"
      "code         msrp"
      "description  description"
      "rate         time";
    }

    .ProductForm > .Image > .Icon {
      height: 3em;
      font-size: 3em;
    }
}

.ProductForm[data-layout="popup"] {

  grid-template-columns: 1fr 4em;
  grid-template-areas:
    "type image"
    "name name"
    "sku  sku"
    "description description";

  > .Code,
  > .MSRP,
  > .DepletionRate,
  > .LeadTime  {
    display: none;
  }
}
