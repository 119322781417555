.SearchPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1.5em;
  grid-template-columns: 1fr;
  grid-gap: 0em;
  grid-template-rows: auto;
  grid-template-areas:
    "Main"
    "Footer";
}

.SearchPopup > .ItemSearchSection {
  grid-area: Main;
  // border-bottom-right-radius: 0;
  // border-bottom-left-radius: 0;
  border-bottom-width: 0;
}

.SearchPopup > .ItemSearchSection > .Items {
  border-top: 1px solid #00609588;
  border-bottom: 1px solid #00609588;
}

.SearchPopup > .ItemSearchSection  .Items,
.SearchPopup > .ItemSearchSection > .QuerySubsection > .Settings {
  height: 50vh;
  overflow-y: scroll;
}

.SearchPopup > .ItemSearchSection[data-expanded="true"] .HeaderLine,
.SearchPopup > .ItemSearchSection[data-expanded="true"] .Items {
  display: none;
}

.SearchPopup > .Footer {
  padding-top: 0em;
  top: -1px;
  border-top-right-radius: 0em;
  border-top-left-radius: 0em;
  border-top-width: 0em;
}

.SearchPopup > .Footer .Stats {
  text-align: left;
  padding-bottom: 1.5em;
}


.SearchPopup[data-expanded="true"] > .Footer {
  display: none;
}
