.LegacyLocationItemForm {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1em;
  grid-template-rows: auto;
  grid-template-areas:
    "name      name      name     control"
    "expected  received  sent     control"
    "quantity  quantity  quantity control";
}

.LegacyLocationItemForm .Input,
.LegacyLocationItemForm .Readout {
  margin: 0px;
}

.LegacyLocationItemForm > .Name {
  grid-area: name;
}

.LegacyLocationItemForm > .Expected {
  grid-area: expected;
}

.LegacyLocationItemForm > .Received {
  grid-area: received;
}

.LegacyLocationItemForm > .Sent {
  grid-area: sent;
}

.LegacyLocationItemForm > .Quantity {
  grid-area: quantity;
}


.LegacyLocationItemForm > .Control {
  grid-area: control;
  align-self: center;
}

.Section[data-size="xsmall"],
.Section[data-size="small"] {
  .LegacyLocationItemForm {
    grid-template-columns: 1fr 1fr 1em;
    grid-template-areas:
      "name     name      control"
      "expected quantity  control";
  }
}

.Section[data-size="medium"],
.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .LegacyLocationItemForm {
    grid-template-columns: 3fr 1fr 1fr 1em;
    grid-template-areas:
    "name  expected quantity control"
  }
}
