.QuerySummaryLine {
  display: flex;
  flex-flow: wrap;
  gap: 0.5em;
  position: relative;
}

.QuerySummaryLine > .Filter {
  margin-right: 1em;
}

.QuerySummaryLine > .Filter > .FilterLabel {
  margin-right: 1em;
  margin-right: 0.25em;
  opacity: 0.65;
}

.QuerySummaryLine.Line > div:first-child {
  text-align: inherit;
}

.QuerySummaryLine.Line > div:last-child {
  text-align: inherit;
}
