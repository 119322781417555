.POPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "filter   filter   filter filter"
    "form     form     form   form"
    ".       .       cancel  ok";
}

.POPopup > .Filter {
  grid-area: filter;
}

.POPopup > .Options {
  grid-area: options;
  text-align: left;
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.POPopup > .Cancel {
  grid-area: cancel;
}

.POPopup > .OK {
  grid-area: ok;
}
