.Popups {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  display: none;
  text-align: center;
  z-index: 100;
}

.Popups[data-populated="true"] {
  display: block;
}

.Popups > .BG {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  /* background-color: rgba(0,0,0,0.75); */
}

.Popups > .Popup {
  display: none;
}

 .Popups > .Popup:last-child {
  display: grid;
}
