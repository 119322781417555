.LoginForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Email"
    "Password";
}

.LoginForm > .Email {
  grid-area: Email;
}

.LoginForm > .Password {
  grid-area: Password;
}
