.PaymentLine {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "Name Name  Paid"
    "Type  .  Amount";
}

.PaymentLine > .Name {
  grid-area: Name;
}

.PaymentLine > .Type {
  grid-area: Type;
  text-align: left;
  font-size: 0.75em;
  opacity: 0.5;
}

.PaymentLine > .Amount {
  grid-area: Amount;
  text-align: right;
  opacity: 0.75;
  color: green;
}

.PaymentLine > .Paid {
  grid-area: Paid;
  text-align: right;
}

@media (min-width: 45.5em) {
  .PaymentLine {
    grid-template-columns: 3fr 1fr 1fr 1fr;
    grid-template-areas:
    "Name  Type Amount Paid";
  }
  .PaymentLine > .Type {
      text-align: inherit;
  }
}
