.TransferItemForm {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1em;
  grid-template-rows: auto;
  grid-template-areas:
    "name name  name     control"
    ".    .     quantity control";
}

.TransferItemForm .Input,
.TransferItemForm .Readout {
  margin: 0px;
}

.TransferItemForm > .Name {
  grid-area: name;
}

.TransferItemForm > .Quantity {
  grid-area: quantity;
}

.TransferItemForm > .Control {
  grid-area: control;
  align-self: center;
}

.Section[data-size="xsmall"],
.Section[data-size="small"] {
  .TransferItemForm {
    grid-template-columns: 1fr 1fr 1em;
    grid-template-areas:
    "name name  name     control"
    ".    .     quantity control";
  }
}

.Section[data-size="medium"],
.Section[data-size="large"],
.Section[data-size="xlarge"] {
  .TransferItemForm {
    grid-template-columns: 3fr 1fr 1em;
    grid-template-areas:
    "name quantity control"
  }
}
