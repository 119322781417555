.LocationItemPopup {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 30em;
  height: 100%;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
}

.LocationItemPopup > .Content {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.LocationItemPopup .Section {
  text-align: center;
}

.LocationItemPopup .Title {
  font-size: 1.5em;
}

.LocationItemPopup .Form {
  display: grid;
  grid-template-rows: auto;
  grid-template-columns: 4em 1fr 1fr;
  grid-gap: 1em;
  align-items: center;
  grid-template-areas:
    "image product product"
    "image   qty     qty";
}


.LocationItemPopup .Form .Image {
  grid-area: image;
}

.LocationItemPopup .Form .Image img {
    max-width: 100%;
    object-fit: contain;
}

.LocationItemPopup .Form .ProductLayout {
  grid-area: product;
}

.LocationItemPopup .Form .Quantity {
  grid-area: qty;
}
