.ItemSearchSection {

  > .QuerySubsection {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 0.5em;

    > .QuerySummaryLine[data-count="0"] {
      display: none;
    }

  }

  > .QuerySubsection > .Settings {
    display: none;
  }

  > .ScrollRail .HeaderLine {
    position: sticky;
    top: 0px;
    background-color: white;
    z-index: 1;
  }

  > .Items {
    > .ItemOptionLine:first-child {
      border-top-color: transparent;
    }
  }

  > .Expanse {
    text-align: left;
    display: none;
  }

  > .Footer {
    text-align: left;
    display: flex;
    flex-flow: column;
    gap: 1em;
    padding-top: 1em;
  }

}

.ItemSearchSection[data-expanded="true"] {
  > .QuerySubsection > .Settings {
    display: grid;
  }

  > .QuerySubsection > .QueryLine {
    grid-template-columns: 1em 1fr 2em 2em;
    grid-template-areas: "Icon Input FilterButton Sort";

    .FilterButton {
      display: block;
      // background-color: #006095;
      color: #f98100;
    }

    .Filter {
      display: none;
    }
  }

  > .Expanse {
    display: block;
  }

  > .Footer {
    display: none;
  }
}

.ItemSearchSection[data-results="false"] {
  // > .HeaderLine {
  //   display: none;
  // }
}
