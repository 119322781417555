.PlainButton {
  width: 1.2em;
  height: 1.2em;
  /* color: #333; */
  font-weight: 400;
  text-align: center;
  line-height: 1.2em;
  display: inline-block;
  background-color: none;
  outline: none;
  border: none;
  padding: 0;
  white-space: nowrap;
  transition: opacity 0.25s;
}

.PlainButton:active {
  color: black;
}

.PlainButton[data-layout="auto"] {
  width: auto;
  height: auto;
  color: inherit;
}

.PlainButton[data-theme="blue"] {
  /* color: #3f4eae; */
  /* color: #006fbb; */
  color: #006095;
}

.PlainButton[data-theme="faint"] {
  color: #555;
  opacity: 0.8;
}

.PlainButton[data-theme="whisper"] {
  color: #999;
  opacity: 0.5;
}

.PlainButton:hover {
  cursor: pointer;
  opacity: 1;
}
