.ClearableVal {
  display: inline-flex;
  font-size: 0.9em;
  background-color: #00609515;
  color: #006095;
  padding: 0.5em;
  border-radius: 3px;
}

.ClearableVal  input {
  font: inherit;
  border: none;
  border-bottom: 1px solid #036095;
  outline: 0;
  background-color: transparent;
}

.ClearableVal > .Icon {
  display: inline-block;
  margin-left: 0.5em;
  cursor: pointer;
}
