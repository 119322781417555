.LocationForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "name"
    "pocFirst"
    "pocLast"
    "street1"
    "street2"
    "street3"
    "city"
    "province"
    "zip"
    "country"
    "phone"
    "email"
    "description";
}

.LocationForm > .Name {
  grid-area: name;
}

.LocationForm > .POCFirst {
  grid-area: pocFirst;
}

.LocationForm > .POCLast {
  grid-area: pocLast;
}

.LocationForm > .Street1 {
  grid-area: street1;
}

.LocationForm > .Street2 {
  grid-area: street2;
}

.LocationForm > .Street3 {
  grid-area: street3;
}

.LocationForm > .City {
  grid-area: city;
}

.LocationForm > .Province {
  grid-area: province;
}

.LocationForm > .Zip {
  grid-area: zip;
}

.LocationForm > .Country {
  grid-area: country;
}

.LocationForm > .Phone {
  grid-area: phone;
}

.LocationForm > .Email {
  grid-area: email;
}

.LocationForm > .Description {
  grid-area: description;
}

@media (min-width: 45.5em) {
  .LocationForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name     ."
      "pocFirst pocLast"
      "street1  street1"
      "street2  street2"
      "street3  street3"
      "city     province"
      "zip      country"
      "phone    phone"
      "email    email"
      "description description";
  }
}

@media (min-width: 65.5em) {
  .LocationForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name     name      .         ."
      "pocFirst pocFirst   pocLast   pocLast"
      "street1  street1   street1   street1"
      "street2  street2   street2   street2"
      "street3  street3   street3   street3"
      "city     city      province  zip"
      ".        .         country   country"
      "phone      phone     email     email"
      "description description description description";
  }
}
