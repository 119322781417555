.ListPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1.5em;
  grid-template-columns: 1fr;
  grid-gap: 0em;
  grid-template-rows: auto;
  grid-template-areas:
    "Main"
    "Footer";
}

.ListPopup > .Section.List {
  grid-area: Main;
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-width: 0;

  .Items {
    height: 50vh;
    overflow-y: scroll;
  }

}


.ListPopup .LoadableArea .InfoLayout:first-child {
  border-top: 1px solid rgba(0,0,0,0.05);
}

.ListPopup .LoadableArea .InfoLayout {
  border-bottom: 1px solid rgba(0,0,0,0.05);
  padding: 0.5em 1.5em;
}

.ListPopup .LoadableArea .Empty {
  position: absolute;
  top: 50%;
  width: 100%;
  transform: translateY(-50%);
}

.ListPopup > .Section.Footer {
  grid-area: Footer;
  top: -1px;
  padding-top: 0em;
  border-top-right-radius: 0em;
  border-top-left-radius: 0em;
  border-top-width: 0em;
}

.ListPopup[data-expanded="true"] > .Footer {
  display: none;
}
