.TransferForm {
  position: relative;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "type     type    type type"
    "status     status    status status"
    "name     name    name   name"
    "source   source  source source"
    "destination destination destination destination"
    "carrier carrier carrier carrier"
    "tracking tracking tracking tracking"
    "sentAt sentAt sentAt sentAt"
    "expectedAt expectedAt expectedAt expectedAt"
    "receivedAt receivedAt receivedAt receivedAt"
    ". . . cost"
    ;
}

.TransferForm > .Type {
  grid-area: type;
}

.TransferForm > .Status {
  grid-area: status;
}

.TransferForm > .Name {
  grid-area: name;
}

.TransferForm > .Source {
  grid-area: source;
}

.TransferForm > .Destination {
  grid-area: destination;
}

.TransferForm > .Carrier {
  grid-area: carrier;
}

.TransferForm > .Tracking {
  grid-area: tracking;
}

.TransferForm > .SentAt {
  grid-area: sentAt;
}

.TransferForm > .ExpectedAt {
  grid-area: expectedAt;
}

.TransferForm > .ReceivedAt {
  grid-area: receivedAt;
}

.TransferForm > .Cost {
  grid-area: cost;
}

.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .TransferForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "type       type        type        type"
      "status     status      status      status"
      "name       name        .           ."
      "source     source      destination destination"
      "carrier    tracking    tracking    tracking"
      "sentAt     sentAt      expectedAt  expectedAt"
      "receivedAt receivedAt  .           cost";
  }
}


.TransferForm[data-layout="popup"] {
  grid-template-columns: 1fr;
  grid-template-areas:
    "type"
    "status"
    "name"
    "source"
    "destination"
    "description";

  > .Carrier,
  > .Tracking,
  > .SentAt,
  > .ExpectedAt,
  > .ReceivedAt,
  > .Cost {
    display: none;
  }
}
