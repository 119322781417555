.PaddedArea {
  padding: 0em 1.5em;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .PaddedArea {
    padding: 0em 1em;
  }
}
