.LoginPopup {
  display: inline-block;
  position: relative;
  width: 100%;
  max-width: 30em;
  height: 100%;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
}


.LoginPopup > .Section {
  text-align: center;
}


.LoginPopup > .Section > .PaddedArea > .Title {
  color: #333;
  font-size: 1.5em;
}

.LoginPopup > .Section > .PaddedArea > .Message {
  color: #333;
}

.LoginPopup[data-unauthorized="true"] > .Section > .PaddedArea > .Message,
.LoginPopup[data-error="true"] > .Section > .PaddedArea > .Message {
  color: red;
}
