.LocationPreviewPage {
  max-width: 934px;
}

.LocationPreviewPage > .Section .Groups {
  display: flex;
  flex-wrap: wrap;
  text-align: left;
  vertical-align:top
}

.LocationPreviewPage > .Section .Group {
  display: block;
  position: relative;
  margin: 1em;
  border: 1px solid black;
  width: 11em;
}

.LocationPreviewPage > .Section[data-size="xsmall"] .Group {
  width: 100%;
}

.LocationPreviewPage > .Section .Group .Name {
  width: 100%;
  font-weight: bold;
  padding: 0.25em 1em;
  background-color: black;
  color: white;
  box-sizing: border-box;
}

.LocationPreviewPage > .Section .Group .Items {
  display: block;
  width: 100%;
}

.LocationPreviewPage > .Section .Group .Items .Item {
  display: grid;
  width: 100%;
  grid-template-columns: 4fr 1fr;
  grid-template: "sku quanity";
  grid-column-gap: 1em;
  padding: 0.2em 1em;
  box-sizing: border-box;
  font-size: 0.8em;
}

.LocationPreviewPage > .Section .Group .Items .Item:hover {
  color: orange;
  cursor: pointer;
}

.LocationPreviewPage > .Section .Group .Items .Item .Sku {
  grid-area: sku;
  display: inline-block;
}
.LocationPreviewPage > .Section .Group .Items .Item .Quantity {
  grid-area: quanity;
  display: inline-block;
  text-align: right;
}
