.Dropdown {
  font-size: 1em;
  position: relative;
  height: 2.2em;
  min-width: 6em;
  border: 1px solid #EEE;
  /*border-radius: 0.5em;*/
  border-radius: 5px;
  text-align: left;
  /*overflow: hidden;*/
}

.Dropdown[data-focused="true"] {
  border-color: #999;
}

.Dropdown .Select {
  height: 100%;
  width: calc(100% - 0em);
  background-color: transparent;
  border: none;
  padding: 0px;
  padding-left: 1em;
  font-size: 1em;
  color:silver;
  overflow: ellipsis;

}

.Dropdown .Select:focus {
  outline-width: 0;
}

.Dropdown .Label {
  position: absolute;
  top: 0px;
  left: 1em;
  opacity: 0.15;
  text-align: left;
  line-height: 2.2em;
  pointer-events: none;
  display: none;
}

 .Dropdown[data-filled="true"] .Label {
  font-size: 0.75em;
  left: 1em;
  transform: translateY(-0.8em);
  background-color: white;
  opacity: 1;
  padding-left: 0.5em;
  padding-right: 0.5em;
  line-height: 1em;
  display: block;
  color: #555;
}

.Dropdown[data-filled="true"] .Label {
  color: #555;
}

.Dropdown[data-filled="true"] .Select {
  color: #555;
}
