.ResolutionsPage {
  display: inline-block;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
}

.ResolutionsPage table.Results {
  thead {
    z-index: 5;
  }

  .Controls {
    min-width: 1em;
    white-space: nowrap;
  }
  .Created {
    min-width: 7em;
    white-space: nowrap;
  }
  .RefName {
    min-width: 10em;
    white-space: nowrap;
  }
  .Origin {
    white-space: nowrap;
    min-width: 5em;
  }
  .Order {
    white-space: nowrap;
    min-width: 5em;
  }
  .Product {
    white-space: nowrap;
    min-width: 8em;
  }
  .Quantity {
    min-width: 5em;
    input { text-align: center; }
  }

  .NewOrder {
    white-space: nowrap;
    min-width: 5em;
  }
  .NewProduct {
    white-space: nowrap;
    min-width: 8em;
  }
  .NewQuantity {
    white-space: nowrap;
    min-width: 3em;
    input { text-align: center; }
  }

  .FeeAmount,
  .ShipAmount {
    min-width: 4em;
    white-space: nowrap;
    input {
      text-align: right;
    }
  }

  .Description,
  .Tracking {
    min-width: 10em;
  }

  .Completed {
    min-width: 7em;
    white-space: nowrap;
  }

  .Controls,
  .Notes {
    text-align: center;
    .FloatDetails > div {
      text-align: left;
    }
  }

  .Notes {
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }
}

.ResolutionsPage table.Results[data-sticky="true"] {
  .Controls,
  .RefName,
  .Order,
  .Product,
  .Quantity,
  .Action
  {
    position: sticky;
    z-index: 2;
  }

  .Controls { left: 0; }
  .RefName { left: 2.5em; }
  .Order { left: 14em; }
  .Product { left: 20.5em; }
  .Quantity { left: 30em; }
  .Action { left: 35em; }

  tr[data-pinned="true"] {
    .Controls { z-index: 5; }
    .Order { z-index: 4;}
    .Product { z-index: 3; }
  }
}

.ResolutionsPage[data-view="support"] table.Results {
  .Carrier,
  .Tracking,
  .NewOrder,
  .State,
  .Completed {
    display: none;
  }
}

.ResolutionsPage[data-view="shipping"] table.Results {
  .RefName,
  .Reason,
  .Carrier,
  .Tracking,
  {
    display: none;
  }

  .Controls { left: 0; }
  .Order { left: 2.5em; }
  .Product { left: 10.2em; }
  .Quantity { left: 19.7em; }
  .Action { left: 24.7em; }
}

.ResolutionsPage .Results .OrderInfo {
  display: flex;
  justify-content: space-between;
  .StoreIcon {
    color: rgb(150, 191, 72);
    margin-left: 1em;
  }
}

.ResolutionsPage .Results tr[data-deleted="true"] {
  text-decoration: line-through !important;
  .DateVal,
  input,
  select {
    pointer-events: none;
    text-decoration: line-through;
  }
  button {
    display: none;
  }
}

.ResolutionsPage .StatTables {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 2em;
}

.ResolutionsPage .StatTables.MetricStats {
  table {
    text-align: center;
    min-width: 7em;
    td {
      font-size: 1.5em;
    }
  }
}

.ResolutionsPage .NarrowStats {
  flex-flow: column;
}
