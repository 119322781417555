.POPaymentForm {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "part     part      part"
    "quantity price     total"
    ".        available capacity";
}

.POPaymentForm .Input,
.POPaymentForm .Readout {
  margin: 0px;
}

.POPaymentForm > .Part {
  grid-area: part;
}

.POPaymentForm > .Quantity {
  grid-area: quantity;
}

.POPaymentForm > .Price {
  grid-area: price;
}

.POPaymentForm > .Total {
  grid-area: total;
}

.POPaymentForm > .Available {
  grid-area: available;
}

.POPaymentForm > .Capacity {
  grid-area: capacity;
}

.POPaymentForm > .Control {
  position: absolute;
  text-align: right;
  align-self: center;
  right: 0px;
  color: red;
}

.POPaymentForm > .Price .Field,
.POPaymentForm > .Total .Field {
  color: green;
  opacity: 0.75;
}


.POPaymentForm[data-action="delete"] > .Control {
  color:black;
}

.POPaymentForm[data-action="delete"] > .SelectorControl {
  opacity: 0.5;
  pointer-events: none;
}

.POPaymentForm[data-action="delete"] > .Input {
  display: none;
}


@media (min-width: 60.5em) {
  .POPaymentForm {
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "part  part   quantity price     total"
    ".     .      .        available capacity";
  }
}
