.DateControl {
  display: grid;
  position: relative;
  grid-template-columns: 2em 2em 4em;
  grid-template-rows: auto;
  grid-template-areas:
    "Label Label Label Label Label"
    "Month Day Year";
  grid-column-gap: 0.5em;
  box-sizing: border-box;
  border-bottom: 1px solid #005590;
  height: 3em;

  > .Label {
    grid-area: Label;
    text-align: left;
    font-size: 0.75em;
  }

  > input {
    border-width: 0px;
    font-size: 1em;
    width: 100%;
    font: inherit;
    text-align: left;
    font-family: "Courier New", Courier, monospace;
    height: 1.2em;
    padding: 0px;
    text-align: center;
    background-color: #add8e62e;
  }

  > .Month {
    position: relative;
    grid-area: Month;
    box-sizing: border-box;
  }

  > .Day {
    position: relative;
    grid-area: Day;
    box-sizing: border-box;
  }

  > .Year {
    grid-area: Year;
    box-sizing: border-box;
  }
}

.DateControl[data-format="ymd"] {
  grid-template-columns: 4em 2em 2em;
  grid-template-areas:
    "Label Label Label Label Label"
    "Year Month Day";
}


.DateControl[data-layout="inline"] {
  grid-template-areas:
    "Month Day Year";
  height: auto;
  border-bottom: none;

  > .Label {
    display: none;
  }
}

.DateControl[data-layout="inline"][data-format="ymd"] {
  grid-template-areas:
    "Year Month Day";
}
