

.OrderStatsPage .StatTables {

  th {
    white-space: nowrap;
    svg {
      opacity: 0.2;
    }
  }

  th[data-sort] {
    svg {
      color: #006095;
      opacity: 0.8;
    }
  }

  th[data-sort="desc"] {
    svg {
      transform: rotate(180deg);
    }
  }
  
}
