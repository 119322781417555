.CheckControl {
  position: relative;
  max-width: 30em;
  display: inline-block;
  color: #006095;
}

.CheckControl > .Button {
  font-size: 1em;
  position: relative;
  height: 2.3em;
  border-radius: 5px;
  text-align: center;
  line-height: 2.1em;
  outline: none;
  opacity: 0.9;
  position: relative;
  box-sizing: border-box;
  white-space: nowrap;
}

.CheckControl[data-layout="table"] > .Button {
  text-align: left;
  color: #333;
  pointer-events: none;
}

.CheckControl > .Button:hover {
  cursor: pointer;
}

.CheckControl[data-layout="field"] {
  min-width: 6em;
}

.CheckControl[data-layout="field"] > .Button {
   border: 1px solid #EEE;
   padding-left: 1em;
   padding-right: 1em;
   color: #555;
}

.CheckControl > .Button > .State {
  display: inline-block;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.CheckControl[data-focused="true"] > .Button > .State {
  transform: rotate(-180deg);
}

.CheckControl[data-layout="table"] > .Button > .State {
  display: none;
}


.CheckControl > .Options {
  text-align: left;
}

.CheckControl > .Options > .Option {
  display: block;
}

.CheckControl[data-layout="dropdown"]  > .Options {
  display: none;
  background-color: white;
  position: absolute;
  max-height: 15em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0px 11px 30px -9px rgba(0,0,0,0.25);
  border-radius: 2px;
  top: 2.3em;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
}



.CheckControl > .Options::-webkit-scrollbar {
  width: 5px;
}

/* .CheckControl > .Options::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.05);
  border-radius: 5px;
} */

.CheckControl > .Options::-webkit-scrollbar-thumb {
  background: #0060951f;
  /* background-color: rgba(0, 0, 0, 0.05); */
  border-radius: 10px;
}

.CheckControl[data-layout="table"] > .Options {
  border-top: 1px solid #ddd;
  display: block;
  position: relative;
}

.CheckControl[data-focused="true"]  > .Options {
  display: block;
  z-index: 10;
}

.CheckControl .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  color: #333;
  cursor: pointer;
}

.CheckControl .Options > .Option:hover {
  color: #006095;
}

.CheckControl[data-layout="dropdown"] .Options > .Option:hover {
  background-color: rgba(0, 0, 0, 0.05);
}
.CheckControl[data-layout="dropdown"] .Options > .Option[data-selected="true"] {
  color: #006095;
  background-color: #00609515;
}

.CheckControl[data-layout="table"] .Options > .Option  {
  padding: 0.5em 0em;
  border: none;
}

.CheckControl .Options > .Option.Clear {
  /* color: #006095; */
  color: #006095aa;
}

.CheckControl .Options > .Option.Clear:hover {
  color: #006095ee;
}

.CheckControl[data-theme="plain"] > .Button  {
  color: #333;
}

.CheckControl[data-theme="plain"] > .Button > .State  {
  display: none;
}
