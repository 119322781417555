.FileLineHeader {
  width: 100%;
  text-align: center;
  font-weight: bold;
  color: #333;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "name name  createdAt";
}

.FileLineHeader > div:first-child {
  text-align: left;
}

.FileLineHeader > div:last-child {
  text-align: right;
}

.FileLineHeader > .Name {
  grid-area: name;
}

.FileLineHeader > .Type {
  grid-area: type;
  display: none;
}

.FileLineHeader > .Size {
  grid-area: size;
  display: none;
}

.FileLineHeader > .Icon {
  grid-area: icon;
  display: none;
}

.FileLineHeader > .CreatedAt {
  grid-area: createdAt;
  text-align: right;
}

@media (min-width: 30.5em) {
  .FileLineHeader {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name size createdAt";
  }
  .FileLineHeader > .Size {
    display: block;
  }
}

@media (min-width: 45.5em) {
  .FileLineHeader {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name type size createdAt";
  }
  .FileLineHeader > .Type {
    display: block;
  }
}

@media (min-width: 60.5em) {
  .FileLineHeader {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "name name type size icon createdAt"
  }
  .FileLineHeader > .Icon {
    display: block;
  }
}
