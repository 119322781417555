.Page.CollectionPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.CollectionPage > div {
  max-width: 65em;
  width: 100%;
}

.CollectionPage .Content .Item {
  border-top: 1px solid transparent;
  padding-top: 1em;
  padding-bottom: 1em;
}

.CollectionPage .Content .Item:first-child {
  padding-top: 0em;
  border-top-width: 0px;
}


.CollectionPage > .Section .LocationItemLineHeader {
  padding-bottom: 0.75em;
}

.CollectionPage > .Section .LocationItemLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}


.CollectionPage .TableSearchSection {
  .ID {
    width: 3em;
    text-align: right;
  }
  .Name {
    width: 100%;
  }
  .Price {
    text-align: right;
    width: 5em;
  }
}
