.Popup {
  display: grid;
  text-align: center;
  align-content: center;
  grid-gap: 1.25em;
  z-index: 20;
  padding: 1em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
}

.Popup > .Section {
  box-shadow: 0px 0px 45px -5px rgba(0, 0, 0, 0.25);
}

.Popup > .Section .QueryLine {
  margin-bottom: 0.5em;
}
.Popup > .Section .QuerySubsection {
  margin-bottom: 1em;
}

.Popup > .Section .HeaderLine {
  padding-bottom:  1em;
  padding-left: 1.5em;
  padding-right: 1.5em;
  box-shadow: 0px 0px 0px 1px white;
}

.Popup > .Section .ItemLine,
.Popup > .Section .ItemOptionLine,
.Popup > .Section .OptionLayout {
  border-top: 1px solid rgba(0,0,0,0.05);
  padding: 0.75em 1.5em;
}

/* .Popup .Footer {
  display: flex;
  gap: 2em;
} */
