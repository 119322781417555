.TypeVal {
  display: inline-block;
  min-width: 5em;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #eee;
  color: rgba(0,0,0,0.75);
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-size: 0.75em;
  opacity: 0.85;
  text-align: center;
  max-height: 1.2em;
}

.TypeVal[data-val="po"],
.TypeVal[data-val="part"] {
  border-color: #9e56bd;
  background-color: #3b016d12;
  color: #7a0007;
}

.TypeVal[data-val="move"],
.TypeVal[data-val="assembly"]  {
  border-color: #6da4bb;
  background-color: #add8e62e;
  color: #3f3fa5;
}

.TypeVal[data-val="stock"],
.TypeVal[data-val="package"] {
  border-color: orange;
  background-color: #faaf1714;
  color: #ab5e00;
}

.TypeVal[data-val="fulfillment"],
.TypeVal[data-val="bundle"] {
  border-color: limegreen;
  background-color: #90ee9014;
  color: darkgreen;
}

.StateVal[data-val="return"],
.StateVal[data-val="discard"] > {
  border-color: red;
  background-color: #ffc0cb30;
  color: firebrick;
}

.TypeVal[data-val="unknown"] > {
  border-color: red;
  background-color: #ffc0cb30;
  color: firebrick;
}

.TypeVal[data-bare="true"] {
  background-color: transparent;
  border: none;
  font-size: inherit;
  padding: 0;
  min-width: auto;
  /* text-decoration: underline; */

}
