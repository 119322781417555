.HomePage {
  display: inline-block;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
}

.HomePage .ScrollRail {
   overflow-x: scroll;
   display: block;
   max-width: 100%;
   height: 65vh;
}

.HomePage .LoadableArea {
  display: table;
  position: relative;
  width: 100%;
}

.HomePage .LoadableArea .SheetLayout:first-child .Value {
  background-color: white;
  position: sticky;
  top: 0;
  font-weight: bold;
  z-index: 1;
}

.HomePage .LoadableArea .SheetLayout.Header .ActionVal {
  width: 100%;
  text-align: center;
}

/* .HomePage .LoadableArea .SheetLayout:hover {
  background-color: rgba(0, 0, 0, 0.05);
} */

.HomePage .LoadableArea .ProductInfo {
  min-width: 17em;
  display: grid;
  grid-template-columns: 2em auto;
  align-items: center;
  grid-gap: 0.5em;
  z-index: 0;
}

.HomePage .LoadableArea .ProductInfo img {
  width: 100%;
}

.HomePage .LoadableArea .Value {
  background-color: white;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.HomePage .LoadableArea .QtyVal {
  text-align: center;
}

.HomePage .Section[data-size="small"],
.HomePage .Section[data-size="xsmall"] {
  font-size: 0.8em;
}

.HomePage .LoadableArea .Value .RowGroup {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
}

.HomePage .LoadableArea .Value .RowGroup .Expected {
  color: grey;
  border-left: 1px solid rgba(0,0,0,0.1);
}
