.OrderForm {
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "number  ."
    "name    ."
    "email   email"
    "notes   notes";
}

.OrderForm > .Number {
  grid-area: number;
}

.OrderForm > .Name {
  grid-area: name;
}

.OrderForm > .Email {
  grid-area: email;
}

.OrderForm > .Notes {
  grid-area: notes;
}

@media (min-width: 45.5em) {
  .OrderForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "number  name"
      "email   email"
      "notes   notes";
  }
}
