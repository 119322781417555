.Page.TransferPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.TransferPage > div {
  max-width: 65em;
  width: 100%;
}

.TransferPage .Content .Item {
  /* border-top: 1px solid #eee; */
  padding-top: 1.5em;
  padding-bottom: 1em;
}

.TransferPage .Content .Item:first-child {
  border-top-width: 0px;
}
