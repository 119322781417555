.DropControl {
  font-size: 1em;
  position: relative;
  height: 3em;
  display: inline-block;
  border-bottom: 1px solid #005590;
}

.DropControl:hover {
  cursor: pointer;
}

.DropControl[data-layout="field"] {
  min-width: 6em;
}

.DropControl[data-layout="inline"] {
  height: 1em;
  border: none;
  padding-right: 1.5em;
}

.DropControl > .Label {
  position: absolute;
  top: 50%;
  left: 0em;
  transform: translateY(-50%);
  text-align: left;
  pointer-events: none;
  font-size: 1em;
  opacity: 0.5;
  transition: all 0.1s;
}

.DropControl > .Value {
  position: relative;
  top:50%;
  transform: translateY(calc(-50% + 0.25em));
  display: block;
  border: none;
  padding: 0em;
  font-size: 1em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: left;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* .DropControl[data-focused="true"]  > .Label, */
/* .DropControl[data-filled="true"] > .Label { */
.DropControl > .Label {
  top: 0em;
  left: 0em;
  transform: none;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}


.DropControl > .State {
  position: absolute;
  display: inline-block;
  right: 0.5em;
  top: 50%;
  transform: rotate(90deg);
  transition: all 0.1s;
  color: #006095;
}

.DropControl[data-layout="inline"] .Label {
  display: none;
}
.DropControl[data-layout="inline"] .Value {
  top: 0;
  transform: none;
}
.DropControl[data-layout="inline"] .State {
  top: 0em;
}

.DropControl[data-working="true"]::after {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  content: " ";
  display: block;
  width: 1em;
  height: 1em;
  border-radius: 50%;
  border: 6px solid transparent;
  border-color: transparent transparent rgba(0,0,0,0.25) transparent;
  border-color: rgba(153,50,204,0.2) rgba(153,50,204,0.2) rgba(138,43,226,0.2) rgba(138,43,226,0.9);
  animation: DropControl-working 0.75s linear infinite;
}

.DropControl[data-working="true"] > .Value {
  opacity: 0.05;
}

.DropControl[data-focused="true"] > .State {
  transform: rotate(-90deg);
}

.DropControl > .Options {
  display: none;
  background-color: white;
  position: absolute;
  max-height: 15em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 6px -6px black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  /* background-color: #eee; */
  color: #777;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid #ddd;
}

.DropControl[data-focused="true"]  > .Options {
  width: 100%;
  display: block;
  z-index: 10;
  box-shadow: 0px 11px 30px -9px rgb(0 0 0 / 25%);
}

.DropControl[data-layout="inline"] > .Options {
  white-space: nowrap;
  width: auto;
  min-width: 100%;
}

.DropControl .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
}

.DropControl .Options > .Option:hover {
  background-color: #eee;
  color: #333;
}

.DropControl .Options > .Option[data-selected="true"] {
  background-color: #00609515;
  color: #006095;
}

.DropControl .Options > .Option > .Entry {
  text-align: left;
  box-sizing: border-box;
}

.DropControl[data-error="true"] {
  border-color: red;
  color: red;
}

.DropControl[data-error="true"] > .Label {
  animation: TextArea-error-shake  infinite 2.5s;
}

@keyframes DropControl-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}

@keyframes DropControl-working {
  0% {
    transform: translate(-50%, -50%) rotate(0deg) ;
  }
  100% {
    transform: translate(-50%, -50%) rotate(360deg) ;
  }
}
