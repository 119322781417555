.ReturnStatsPage {
  display: inline-block;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
}

.ReturnStatsPage .PageHeaderOutline {
  .DropControl {
    color: #006095;
  }
}

.ReturnStatsPage .SearchSection {
  .QueryOptionsPresentation {
    display: none;
  }
}

.ReturnStatsPage .SearchSection[data-expanded="true"] {

  .FilterButton {
    display: block;
    background-color: #006095;
    color: white;
  }

  .QueryOptionsPresentation {
    display: grid;
  }
}

.ReturnStatsPage .StatTables {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 2em;
}


.ReturnStatsPage .StatTables {

  th {
    white-space: nowrap;
    svg {
      opacity: 0.2;
    }
  }

  th[data-sort] {
    svg {
      color: #006095;
      opacity: 0.8;
    }
  }

  th[data-sort="desc"] {
    svg {
      transform: rotate(180deg);
    }
  }

  .Multiheader {
    text-align: center;
  }

  .Period {
    background-color: rgba(0, 255, 255, 0.05);
  }
  .Subset {
    background-color: rgba(255, 255, 0, 0.05);
  }
  .Fullset {
    background-color: rgba(255, 0, 255, 0.05);
  }

  tr[data-blank="true"] {
    opacity: 0.5;
  }
  .Name {
    width: 10em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Count,
  .Sold {
    text-align: center;
  }
  .Rate {
    text-align: right;
  }
}

.ReturnStatsPage .StatTables.MetricStats {
  table {
    text-align: center;
    td {
      font-size: 1.5em;
    }
  }
}

.ReturnStatsPage .NarrowStats {
  flex-flow: column;
}
