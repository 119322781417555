.TransferLineHeader {
  width: 100%;
  text-align: center;
  font-weight: bold;
  /* color: #333; */
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "name name  createdAt";
}

.TransferLineHeader > div:first-child {
  text-align: left;
}

.TransferLineHeader > div:last-child {
  text-align: right;
}

.TransferLineHeader > .Name {
  grid-area: name;
}

.TransferLineHeader > .Type {
  grid-area: type;
  display: none;
}

.TransferLineHeader > .Status {
  grid-area: status;
  display: none;
}

.TransferLineHeader > .Cost {
  grid-area: cost;
  display: none;
}

.TransferLineHeader > .CreatedAt {
  grid-area: createdAt;
  text-align: right;
}

@media (min-width: 30.5em) {
  .TransferLineHeader {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name status createdAt";
  }
  .TransferLineHeader > .Status {
    display: block;
  }
}

@media (min-width: 45.5em) {
  .TransferLineHeader {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name name type status createdAt";
  }
  .TransferLineHeader > .Type {
    display: block;
  }
}

@media (min-width: 60.5em) {
  .TransferLineHeader {
    grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "name name type status cost createdAt"
  }
  .TransferLineHeader > .Cost {
    display: block;
  }
}
