.Input {
  font-size: 1em;
  position: relative;
  min-width: 6em;
  text-align: left;
  display: inline-block;
}

.Input[data-focused="true"] {
  /* border-color: #999; */
  /* border-color: #0070c9; */
}

.Input[data-labeled="true"] {
  height: 3em;
}

.Input > .Field {
  position: relative;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0px;
  font-size: 1em;
  transition: all 0.1s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
}

.Input[data-labeled="true"] > .Field {
  border-radius: 5px;
  padding-left: 0.5em;
  padding-top: 1.5em;
  padding-bottom: 0.5em;
  background-color: rgba(0,0,0,0.05);
  box-sizing: border-box;
}

.Input[data-clearable="true"] > .Field {
  padding-right: 2em;
  width: calc(100% - 3em);
}

.Input[data-dirty="true"] > .Field {
  color: #881381;
}

.Input > .Control {
  display: none;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.5em;
  padding: 0.75em;
  opacity: 0.5;
  font-size: 1.35em;
}

.Input > .Control:hover {
  cursor: pointer;
}

.Input[data-filled="true"][data-clearable="true"] > .Control {
  display: block;
}

.Input > .Label {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  transform: none;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}

.Input[data-focused="true"]  > .Label {
  color: #006095;
  font-weight: bold;
}

.Input[data-labeled="true"][data-focused="true"] > .Field {
  background-color: rgba(0,85,144,0.05);
}

.Input[data-lock="true"] {
  pointer-events: none;
}
.Input[data-lock="true"] > .Field {
  background-color: rgba(0,0,0,0.05);
  color: gray;
}

.Input[data-error="true"] > .Field {
  border-color: red;
}

.Input[data-error="true"] > .Label {
  color: red;
}
.Input[data-focused="false"][data-error="true"] > .Label {
  animation: Input-error-shake  infinite 2.5s;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .Input {
    width: 100%;
  }
}

@keyframes Input-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
