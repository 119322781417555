.SelectorControl {
  position: relative;
  max-width: 30em;
  display: inline-block;
}

.SelectorControl > .Header > .Input {
  position: relative;
  margin-right: 0px;
  width: 100%;
}

.SelectorControl > .Options {
  display: none;
  background-color: white;
  width: 100%;
  position: absolute;
  max-height: 15em;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  box-shadow: 0 2px 6px -6px black;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  background-color: #eee;
  color: #777;
  transform: translateY(-1em);
}

.SelectorControl[data-focused="true"]  > .Options,
.SelectorControl  > .Options:hover {
  display: block;
  z-index: 10;
}

.SelectorControl .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
}


.SelectorControl .Options > .Option:hover {
  background-color: #eee;
  color: #333;
}

.SelectorControl .Options > .Option > .Entry {
  text-align: left;
  box-sizing: border-box;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .SelectorControl {
    width: 100%;
  }
}
