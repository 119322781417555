.FileUploadLayout {
  width: 100%;
  max-width: 10em;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 0.25em;
  grid-template-rows: auto;
  align-items: center;
  grid-template-areas:
    "Icon   Icon  Icon"
    "Title  Title Title"
    "Sub    Sub   Sub";
  border: 2px dashed #00609577;
  box-sizing: border-box;
  padding: 0.5em;
  border-radius: 5px;
  cursor: pointer;
}

.FileUploadLayout:hover {
  /* background-color: #eee; */
}

.FileUploadLayout > .Icon {
  position: relative;
  grid-area: Icon;
  display: inline-block;
  align-content: center;
  text-align: center;
  width: 100%;
  height: 3em;
  padding-bottom: 0.25em;
  object-fit: contain;
  color: #00609577;
}

.FileUploadLayout > .Title {
  grid-area: Title;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.FileUploadLayout > .Sub {
  grid-area: Sub;
  text-align: center;
}

.FileUploadLayout > .Input {
  display: none;
}

.FileUploadLayout[data-working="true"] > .Icon {
  animation: WorkingFileUpload  1s linear infinite;
  box-sizing: border-box;
}

@keyframes WorkingFileUpload {
  0% {
    color: #00609577;
  }
  20% {
    color: #00609577;
    padding: 0.25em;
  }
  100% {
    color: #00609577;
  }
}
