.SelectPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1.5em;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "filter   filter   filter filter"
    "options options options options"
    ".       .       cancel  ok";
}

.SelectPopup > .Filter {
  grid-area: filter;
}

.SelectPopup > .Options {
  grid-area: options;
  text-align: left;
  max-height: 20em;
  overflow-y: scroll;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

.SelectPopup > .Cancel {
  grid-area: cancel;
}

.SelectPopup > .OK {
  grid-area: ok;
}

.SelectPopup > .Options > .Option {
  box-sizing: border-box;
  padding: 1em;
  border-bottom: 1px solid #ddd;
  white-space: nowrap;
  color: #777;
}

.SelectPopup > .Options > .Option.Other {
  color: #3f4eae;
}

.SelectPopup > .Options > .Option:hover {
  background-color: #eee;
  color: #333;
}

.SelectPopup > .Options > .Option > .Check {
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 3px;
  border: 1px solid #ddd;
  box-sizing: border-box;
  margin-right: 0.5em;
  background-color: white;
  position: relative;
}

.SelectPopup > .Options > .Option.Other > .Check {
  opacity: 0;
}

.SelectPopup > .Options > .Option[data-selected="true"] {
  background-color: rgba(63, 78, 174, 0.05);
}

.SelectPopup[data-type="radio"] > .Options > .Option > .Check {
  border-radius: 6px;
}

.SelectPopup[data-type="radio"] > .Options > .Option[data-selected="true"] > .Check {
  background-color: #3f4eae;
  border: 3px solid white;
}

.SelectPopup[data-type="check"] > .Options > .Option[data-selected="true"] > .Check::before {
  position: absolute;
  display: block;
  content: "";
  color: transparent;
  top: -2px;
  left: -2px;
  width: 12px;
  height: 6px;
  border-left: 2px solid #3f4eae;
  border-bottom: 2px solid #3f4eae;
  transform: rotate(-50deg);
}

.SelectPopup > .Options > .Indicator {
  position: absolute;
  top: calc(50% - 35px);
  left: calc(50% - 35px);
  height: 70px;
  width: 70px;
  border-radius: 50%;
  font-size: 3em;
  border: solid 10px transparent;
  border-left-color: #6774c8;
  animation: App-logo-spin infinite 0.85s linear;
  box-sizing: border-box;
  display: none;
}

.SelectPopup > .Options[data-working="true"] {
  min-height: 5em;
  overflow: hidden;
}

.SelectPopup > .Options[data-working="true"]  > div {
  opacity: 0.25;
}

.SelectPopup > .Options[data-working="true"] > .Indicator {
  display: block;
  opacity: 1;
}
