.ImageLayout {
  position: relative;
  color: silver;
}

.ImageLayout[data-upload="true"] {
  color: #006095;
}

.ImageLayout[data-upload="true"]:hover {
  cursor: pointer;
  opacity: 0.9;
}

.ImageLayout > .Input {
  display: none;
}

.ImageLayout > .Icon {
  position: relative;
  display: inline-block;
  align-content: center;
  text-align: center;
  object-fit: contain;
}

.ImageLayout[data-status="uploading"] > .Icon {
  color: #006095;
  animation: loadable-area-indicator-spin infinite 0.85s linear;
}


@keyframes image-layout-indicator-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
