.ModelLayout {
  width: 100%;
  display: grid;
  grid-gap: 0.5em;
  grid-template-columns: auto 1fr;
  grid-template-areas:
    "Label  Sublabel "
    "Name   Name";
}

.ModelLayout > .Image {
  grid-area: Image;
  text-align: left;
}

.ModelLayout > .Name {
  grid-area: Name;
  text-align: left;
  text-overflow: ellipsis;
  overflow-y: hidden;
}

.ModelLayout > .Label {
  grid-area: Label;
  text-align: left;
  font-size: 0.8em;
}

.ModelLayout > .Sublabel {
  grid-area: Sublabel;
  text-align: right;
  font-size: 0.8em;
  opacity: 0.5;
  white-space: nowrap;
}

.ModelLayout[data-image="false"] {
  grid-template-columns: 20px auto 1fr;
  grid-template-areas:
    "Image  Label  Sublabel "
    "Image  Name   Name";
}
