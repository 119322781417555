
.Pages {
  position: absolute;
  text-align: center;
  display: inline-block;
  height: 100vh;
  top:0px;
  left: 16em;
  box-sizing: border-box;
  padding-top: 3em;
}


/*
.Pages {
  position: absolute;
  text-align: center;
  display: inline-block;
  height: 100vh;
  top: 0px;
  left: 16em;
  box-sizing: border-box;
  padding-top: 3em;
  width: calc(100% - 16em);
  background-color: yellow;
}
*/

*[info]:hover {
  position: relative;
}

*[info]:hover:after {
  content: attr(info);
  position: absolute;
  left: 0;
  top: 100%;
  /* width: 100%; */
  font-weight: normal;
  padding: 0.5em;
  background-color: rgb(240, 240, 240);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  color: rgba(0,0,0,0.5);
  font-size: 0.9em;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.25);
}

/* Small screens */
@media (max-width: 60.5em) {
  .Pages {
    // width: 100%;
    left: 0em;
  }
}

a[theme="blue"] {
  color: #006095;
  text-decoration: none;
}

input {
  font-size: inherit;
  color: inherit;
  text-align: inherit;
  -webkit-appearance: none;
}

select {
  border-color: #26689c;
  border-radius: 5px;
  padding: 0.1em 0.2em;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  /*
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  */
}

select[disabled] {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
}

svg[data-filled="true"] {
  color: #036095;
}

svg[data-filled="false"] {
  color: silver;
}

table {
  tr > th,
  tr > td {
    background-color: white;
  }

  tr[data-creating="true"] {
    > td {
      background-color: #f7fef8;
      box-shadow: inset 0 0 5px rgba(0,248,92,0.2);
    }
  }

  tr[data-expanded="true"] {
    > td {
      background-color: #f9fdff;
    }
  }

  tr[data-pinned="true"],
  tr[data-selected="true"] {
    > td {
      background-color: #eef9ff;
    }
  }

  tr[data-working="true"] {
    > td {
      background-color: white;
      animation: tr-working infinite 1.5s;
      pointer-events: none;
      box-shadow: none;
    }
  }


}

table.Card {
  background-color: white;
  box-shadow: 0px 5px 10px rgba(0,0,0,0.1);
  text-align: left;
  padding: 1em;
  border-radius: 5px;
  border-collapse: separate;
  border-spacing: 0;
  padding: 0;

  td {
    padding-right: 1em;

    input,
    select {
      border: none;
      background-color: transparent;
    }
    input { width: 100%; }
  }

  tr:hover > td {
    box-shadow: inset 0 0 100px 0px rgba(0,0,0,0.03);
  }

  tfoot {
    box-shadow: inset 0px 2px 4px 0px rgba(0,0,0,0.10);
    td {
      opacity: 0.5;
      // font-size: 0.8em;
    }
  }

  tfoot tr:first-child td {
    padding-top: 0.5em;
  }
}

table.StickyHeader {
  position: relative;

  thead {
    background-color: white;
    position: sticky;
    top: 3em;
    z-index: 1;
    box-shadow: 0px 2px 4px 0px rgba(0,0,0,0.1);
  }

  th, td {
    border: 1px solid rgba(0,0,0,0.05);
  }
  th {
    border-top: none;
  }
  th, td {
    padding: 0.5em 0.75em;
  }

  td:first-child,
  th:first-child {
    border-left: none;
  }
  td:last-child,
  th:last-child {
    border-right: none;
  }
}


table[animate="loading"] {
  animation: animation-fading infinite 2s linear;
  pointer-events:none;
  user-select: none;
  tr {
    color: transparent;
    background-color: rgba(0,0,0,0.07);
  }
}

*[data-hype="fedex"] { border-color: #FF6600; color: #4D148C; }
*[data-hype="usps"] { border-color: #DA291C; color: #004B87; }
*[data-hype="ups"] { border-color: #FFB500; color: #a63b1d; }
*[data-hype="dhl"] { border-color: #FFCC00; color: #D40511; }

*[data-hype="no"] { border-color: #D40511; color: #D40511; }
*[data-hype="yes"] { border-color: #008f01; color: #005a01; }

*[data-hype="damage"] { border-color: #641E16; color: #641E16 ; }
*[data-hype="defective"] { border-color: #78281F; color: #78281F; }
*[data-hype="description"] { border-color: #512E5F; color: #512E5F; }
*[data-hype="design"] { border-color: #00a0a0; color: #00a0a0; }
*[data-hype="fraud"] { border-color: #ff6000; color: #ff6000; }
*[data-hype="functionality"] { border-color: #79a000; color: #79a000; }
*[data-hype="expectations"] { border-color: #154360; color: #154360; }
*[data-hype="gift"] { border-color: #1B4F72; color: #1B4F72; }
*[data-hype="incompatible"] { border-color: #99008c; color: #99008c; }
*[data-hype="mistake"] { border-color: #0E6251; color: #0E6251; }
*[data-hype="need"] { border-color: #0B5345; color: #0B5345; }
*[data-hype="performance"] { border-color: #145A32; color: #145A32; }
*[data-hype="price"] { border-color: #145A32; color: #145A32; }
*[data-hype="quality"] { border-color: #ed7700; color: #ed7700; }
*[data-hype="remorse"] { border-color: #7D6608; color: #7D6608; }
*[data-hype="shipping-mistake"] { border-color: #d40511; color: #d40511; }
*[data-hype="support"] { border-color: #d40511; color: #d40511; }
*[data-hype="timeliness"] { border-color: #7E5109; color: #7E5109; }
*[data-hype="undeliverable"] { border-color: #784212; color: #784212; }
*[data-hype="wardrobing"] { border-color: #6E2C00; color: #6E2C00; }
*[data-hype="wrong"] { border-color: #CD5C5C; color: #CD5C5C; }
*[data-hype="other"] { border-color: #706050;  color: #a09080; }

*[data-hype="initiated"] { border-color: #004B87; color: #004B87; }
*[data-hype="received"] { border-color: #9a4502; color: #9a4502; }
*[data-hype="processed"] { border-color: #4D148C; color: #4D148C; }
*[data-hype="incomplete"] { border-color: #ed7700; color: #ed7700; }
*[data-hype="complete"] { border-color: #005a01; color: #005a01; }
*[data-hype="invoiced"] { border-color: #be00a5; color: #be00a5; }

*[data-hype="pending"] { border-color: #004B87; color: #004B87; }
*[data-hype="decline"] { border-color: #870000; color: #870000; }

*[data-hype="unknown"] { border-color: silver; color: silver; }
*[data-hype="shipped"] { border-color: #004B87; color: #004B87; }
*[data-hype="held"] { border-color: #4D148C; color: #4D148C; }
*[data-hype="lost"] { border-color: #D40511; color: #D40511; }

*[data-hype="pending"] { border-color: #ed7700; color: #ed7700; }
*[data-hype="fulfilled"] { border-color: #005a01; color: #005a01; }

*[data-hype="active"] { border-color: #6da4bb; color: #6da4bb; }
*[data-hype="spent"] { border-color: darkorange; color: darkorange; }
*[data-hype="refunded"] { border-color: darkred; color: darkred; }

*[data-hype="create"] { border-color: #6dbb80; color: #2e9419; }
*[data-hype="update"] { border-color: #6da4bb; color: #137aa2; }
*[data-hype="delete"] { border-color: darkred; color: darkred; }

*[data-hype="error"] { border-color: red; color: red; }
*[data-hype="undefined"] { border-color: #9c480942; color: #9c480942; }
*[data-hype="blank"] { border-color: rgba(0,0,0,0.15); color: rgba(0,0,0,0.15); }


*[data-hype="part"] { border-color: #ae56bd; color: #9a0007; }
*[data-hype="assembly"]  { border-color: #6da4bb; color: #3f3fa5; }
*[data-hype="package"] { border-color: orange; color: #d46a00; }
*[data-hype="bundle"] { border-color: limegreen; color: darkgreen;}

*[data-hype="credit"] { border-color: #b85c00; color: #b85c00; }
*[data-hype="partial-credit"] { border-color: #b85c00; color: #b85c00; }
*[data-hype="refund"] { border-color: #e93300; color: #e93300; }
*[data-hype="partial-refund"] { border-color: #e93300; color: #e93300; }
*[data-hype="disregard"] { border-color: #a5a500; color: #a5a500; }
*[data-hype="exchange"] { border-color: #7e5289; color: #7e5289; }

*[data-hype="complimentary"] { border-color: #2eb8b9; color: #2eb8b9; }
*[data-hype="customized"] { border-color: #8ab92e; color: #8ab92e; }
*[data-hype="replace"] { border-color: #9b9400; color: #9b9400; }
*[data-hype="reship"] { border-color: #c95c00; color: #c95c00; }

*[data-hype="subval"] { border-color: #888888; color: #888888; }
*[data-hype="strong"] { border-color: #333333; color: #333333; }

*[data-hype="on-time"] { border-color: green; color: green; }
*[data-hype="late"] { border-color: red; color: red; }
*[data-hype="expired"] { border-color: #e29f79; color: #e29f79; }

*[data-hype="normal"] { border-color: #569469; color: #569469; }
*[data-hype="urgent"] { border-color: #945656; color: #945656; }

*[data-hype="whisper"] { border-color: grey; color: grey }


*[data-hype="new"] { border-color: #004b87; color: #004b87; }
*[data-hype="deposited"] { border-color: purple; color: purple; }
*[data-hype="paid"] { border-color: seagreen; color: seagreen; }
*[data-hype="canceled"] { border-color: red; color: red; }



@keyframes tr-working {
  0% {
    background-color: white;
  }
  20% {
    background-color: rgba(250,220,255,1);
  }
  50% {
    background-color: rgba(250,250,255,1);
  }
  75% {
    background-color: rgba(250,240,255,1);
  }
  100% {
    background-color: white;
  }
}

@keyframes animation-fading {
  0% {
    opacity: 1;
  }
  20% {
    opacity: 0.35;
  }
  50% {
    opacity: 1;
  }
  75% {
    opacity: 0.85;
  }
  100% {
    opacity: 1;
  }
}


.ControlIcon {

}

details.FloatDetails {
  position: relative;

  > summary {
    list-style-type: none;
    list-style: none;
    color: #036096;
    opacity: 0.75;
    transition: opacity 0.2s;
    cursor: pointer;
  }
  > summary:hover {
    opacity: 1;
  }
  > summary::-webkit-details-marker {
    display: none;
  }
  > div {
    position: absolute;
    background-color: #f8f8f8;
    width: 13em;
    box-shadow: 0px 4px 8px 0px rgba(0,0,0,0.20);
    padding: 1em;
    border-radius: 5px;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    z-index: 1;

    > h1, h3, h3, h4 {
      margin: 0;
    }

    > .CloseButton {
      position: absolute;
      top: 1em;
      right: 1em;
    }
  }

  .ProductLayout .ActionVal {
    // overflow-x: hidden;
    // text-overflow: ellipsis;
    overflow-x: clip;
    white-space: normal;
  }
}

details[open] {
  summary {
    * {
      opacity: 1;
      color: #ff8600;
    }
  }
}

details .DetailsList {
  display: flex;
  gap: 1em;
  flex-flow: column;

  .Header {
    display: flex;
    justify-content: space-between;
  }
  .Line {
    display: flex;
    justify-content: space-between;
  }
}

.IconAction {
  display: flex;
  gap: 0.75em;
  color: #006095;
  opacity: 0.8;
  transition: opacity 0.5s;

  svg {
    min-width: 1.5em;
  }
}

.IconAction:hover {
  cursor: pointer;
  opacity: 1;
}

.IconAction:active{
  color: #ff8600;
}

.ShopifyIcon {
  color: #96bf48;
}

.TinySummary {
  font-size: 0.8em;
  color: #777;
  width: 100%;
  overflow-x: scroll;
  padding: 0.5em;
  background-color: rgba(0,0,0,0.05);
  border-radius: 5px;
  box-sizing: border-box;

  .OldVal {
    color: rgba(255,0,0,0.5);
  }
}

.ImageMini {
  width: 2em;
  // height: 2em;
  object-fit: contain;
  background-color: white;
  // border: 1px solid rgba(0,0,0,0.05);
}


.ModelSearchSection {
  .Results {

    th.Controls {
      color: #006095;
    }

    // tr:nth-child(even) td {
    //   background-color: rgba(0,0,0,0.025);
    // }

    tr td {
      border-bottom: 1px solid rgba(0,0,0,0.05);
    }

    input {
      border-radius: 5px;
      padding: 3px 5px;
      // background-color: #0060950a;
      background-color: #00609514;
    }

    input:hover {
      background-color: white;
      box-shadow: 0px 0px 0px 1px rgba(0,0,0,0.05);
    }

    input:focus {
      // background-color: rgba(0,96,148,0.05);
      background-color: white;
      box-shadow: 0px 0px 0px 1px rgba(0,96,148,0.25);
    }

    input[data-dirty="true"] {
      box-shadow: 0px 0px 3px 1px rgba(138,43,225,0.75);
    }
  }
}
