.Page.ReturnsKPIPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.ReturnsKPIPage > div {
  max-width: 65em;
}

.ReturnsKPIPage .TableSearchSection {
  width: 100%;
}

.ReturnsKPIPage .Results {

  .Email {
    width: 50%;
    text-align: left;
  }

  .Name {
    width: 50%;
    white-space: nowrap;
  }

  .Total {
    width: 5em;
    text-align: right;
  }

}
