.Notifier {
  position: fixed;
  z-index: 1000;
  width: 20em;
  min-height: 5px;
  right: 1em;
  top: 0;

  .Notification {
    position: relative;
    border-radius: 5px;
    background-color: #00ffb9f2; //#00ffc0;
    margin-top: 1em;
    padding: 1em;
    box-shadow: 0 3px 7px -3px rgba(0,0,0,0.50);
    animation: Notification-Present 0.5s ease-out;
    width: calc(100%-2em);

    .Close {
      position: absolute;
      display: block;
      top: 0px;
      right: 0px;
      padding: 1em;
      opacity: 0.5;
      cursor: pointer;
    }

    .Message {
      display: inline-block;
      padding-left: 0.5em;
    }

    details > summary::marker {
      padding-right: 1em;
    }

    details > p {
      white-space: pre-wrap;
      overflow-y: scroll;
      max-height: 15em;
      background-color: rgba(255,255,255,0.9);
      padding: 0.5em;
      border-radius: 5px;
      font-size: 0.9em;
      font-family: monospace;
    }
  }

  .Notification[data-hidden="true"] {
    animation: Notification-Exit 0.5s ease-in;
    -webkit-animation-fill-mode: forwards; /* Chrome 16+, Safari 4+ */
    -moz-animation-fill-mode: forwards;    /* FF 5+ */
    -o-animation-fill-mode: forwards;      /* Not implemented yet */
    -ms-animation-fill-mode: forwards;     /* IE 10+ */
    animation-fill-mode: forwards;
  }

  .Notification[data-kind="error"] {
    background-color: #c10000f2;
    .Close {
      color: white;
    }
    details > summary {
      color: white;
    }
  }
}


@keyframes Notification-Present {
  0% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

@keyframes Notification-Exit {
  0% {
    opacity: 0.9;
  }
  25% {
    color: transparent;
    opacity: 0;
  }
  100% {
    max-height: 0px;
    margin: 0;
    padding: 0;
    box-shadow: none;
    color: transparent;
    opacity: 0;
    pointer-events: none;
  }
}
