.Page.POPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.POPage > div {
  max-width: 65em;
  width: 100%;
}

.POPage .Content .Item {
  border-top: 1px solid transparent;
  padding-top: 1em;
  padding-bottom: 1em;
}

.POPage .Content .Item:first-child {
  padding-top: 0em;
  border-top-width: 0px;
}

.POPage > .Section .TransferLineHeader,
.POPage > .Section .FileLineHeader,
.POPage > .Section .PaymentLineHeader {
  padding-bottom: 0.75em;
}

.POPage > .Section .TransferLine,
.POPage > .Section .FileLine,
.POPage > .Section .PaymentLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}



.POPage table.Results {

  .Controls {
    position: relative;
    width: 1em;
    white-space: nowrap;
    text-align: center;
    font-size: 0.9em;
  }
  .Controls:hover {
    cursor:pointer;
  }

  .Type {
    width: 5em;
  }

  .Amount {
    width: 5em;
    text-align: right;
  }

  .Paid {
    width: 5em;
  }

  .Notes {
    text-align: center;
    width: 2em;
  }

  .Notes {
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }

  tr[data-creating="true"] td.Controls {
    opacity: 0.5;
    color: red;
  }

}
