.LineTitle {
  color: silver;
  font-size: 1em;
  position: relative;
  height: 2.2em;
  min-width: 6em;
  text-align: left;
  font-weight: bold;
}

.LineTitle .Label {
  position: absolute;
  top: 0px;
  left: 0.25em;
  text-align: left;
  line-height: 2.2em;
  pointer-events: none;
  text-transform: uppercase;
}

.LineTitle aside {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0.25em;
  text-align: right;
}
