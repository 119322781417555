.RoundButton {
  width: 1.2em;
  height: 1.2em;
  color: silver;
  border: 1px solid silver;
  border-radius: 50%;
  font-weight: 400;
  text-align: center;
  line-height: 1.2em;
  overflow: hidden;
  display: inline-block;
}

.RoundButton:hover {
  border: 1px solid darkgrey;
  color: darkgrey;
  cursor: pointer;
}

.RoundButton:active {
  background-color: #faaf17;
  color: black;
}
