.StateVal {
  display: inline-block;
  min-width: 5em;
  font-size: 0.75em;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #eee;
  color: rgba(0,0,0,0.75);
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-size: 0.75em;
  opacity: 0.85;
  text-align: center;
}

.StateVal[data-val="new"],
.StateVal[data-val="active"] {
  border-color: #6da4bb;
  background-color: #add8e62e;
  color: #3f3fa5;
}

.StateVal[data-val="deposited"]  {
  border-color: #9e56bd;
  background-color: #3b016d12;
  color: #3a016b;
}

.StateVal[data-val="sent"],
.StateVal[data-val="paid"],
.StateVal[data-val="spent"] {
  border-color: orange;
  background-color: #faaf1714;
  color: #ab5e00;
}

.StateVal[data-val="received"],
.StateVal[data-val="fulfilled"] {
  border-color: limegreen;
  background-color: #90ee9014;
  color: darkgreen;
}

.StateVal[data-val="canceled"],
.StateVal[data-val="refunded"] {
  border-color: red;
  background-color: #ffc0cb30;
  color: firebrick;
}
