.Page.LocationPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.LocationPage > div {
  max-width: 65em;
  width: 100%;
}

.LocationPage .Content .Item {
  border-top: 1px solid transparent;
  padding-top: 1em;
  padding-bottom: 1em;
}

.LocationPage .Content .Item:first-child {
  padding-top: 0em;
  border-top-width: 0px;
}

.LocationPage .TableSearchSection.Products {
  .Incoming,
  .Stock {
    width: 5em;
    text-align: right;
  }
}

.LocationPage .TableSearchSection.Transfers {
  .Name {
    width: 100%;
  }
  .Source {
    width: 7em;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .Incoming,
  .Stock,
  .Expected,
  .Received {
    width: 5em;
  }
}

.LocationPage > .Section .TransferLineHeader {
  padding-bottom: 0.75em;
}

.LocationPage > .Section .TransferLine {
  border-top: 1px solid #eee;
  padding-top: 0.75em;
  padding-bottom: 0.75em;
}
