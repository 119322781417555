.VendorPopup {
  width: 100%;
  max-width: 30em;
  box-sizing: border-box;
  padding: 1em;
  text-align: center;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: auto;
  grid-template-areas:
    "filter   filter   filter filter"
    "form     form     form   form"
    ".       .       cancel  ok";
}

.VendorPopup > .Form {
  grid-area: form;
}

.VendorPopup > .Cancel {
  grid-area: cancel;
}

.VendorPopup > .OK {
  grid-area: ok;
}
