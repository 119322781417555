.PaginateControl {
  position: relative;
  display: inline-block;
  white-space: nowrap;
  background-color: white;
}

.PaginateControl > .Prev {
  grid-area: Prev;
  text-align: left;
  padding-right: 1em;

}

.PaginateControl > .Center {
  display: inline-block;
  grid-area: Center;
  text-align: center;
  padding: 0.5em 0.75em;
}

.PaginateControl > .Next {
  grid-area: Next;
  text-align: right;
  padding-left: 1em;
}

.PaginateControl > .Prev,
.PaginateControl > .Next {
  display: inline-block;
  pointer-events: none;
  color: #dddddd;
}


.PaginateControl[data-less="true"] > .Prev,
.PaginateControl[data-more="true"] > .Next {
  pointer-events:inherit;
  color: #006095;
  cursor: pointer;
}

.PaginateControl[data-border="true"] {
  border: 1px solid #dddddd;
  border-radius: 5px;
}

.PaginateControl[data-border="true"] > .Prev,
.PaginateControl[data-border="true"] > .Next {
  width: 1em;
  padding: 0.5em;
}

.PaginateControl[data-border="true"] > .Center,
.PaginateControl[data-border="true"] > .Next {
  border-left: 1px solid #dddddd;
}
