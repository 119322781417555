.Page {
  display: inline-block;
  text-align: center;
  padding: 2em;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
  min-width: calc(100vw - 19.5em);
}

.Page.Legacy {
  min-width: auto;
}

.Page > div {
  max-width: calc(100vw - 19.5em);
  position: sticky;
  left: 17.5em;
}

.Page > .Section .QueryLine {
  margin-bottom: 0.5em;
}
.Page > .Section .QuerySubsection {
  margin-bottom: 0.5em;
}

.Page > .Section .HeaderLine,
.Page > .Section .TitleLine {
  margin-bottom:  0.75em;
  padding-left: 1.5em;
  padding-right: 1.5em;
}

.Page > .Section .ItemLine,
.Page > .Section .RowLine {
  border-top: 1px solid #dddddd;
  padding: 0.75em 1.5em;
}

.Page > .Section .Form {
  border-top: 1px solid #dddddd;
  padding: 1em 1.5em;
}

.Page > .PageHeaderOutline,
.Page > .Section,
.Page > table {
  margin-bottom: 2em;
}

.Page > .Header {
  padding-top: 1em;
  padding-bottom: 1em;
  display: flex;
  justify-content: space-between;
}

.Page > .Header > div {
  display: inline-block;
}

.Page > .Header > .Title {
  color: darkblue;
  height: 2.2em;
  font-size: 1.2em;
  font-weight: bold;
  line-height: 2.2em;
}

/* Phone screens */
@media (max-width: 60.5em) {
  .Page {
    padding-top: 1em;
    padding-bottom: 1em;
    padding-left: 0px;
    padding-right: 0px;
    min-width: calc(100vw - 2em);
  }

  .Page > div {
    max-width: calc(100vw - 2em);
    left: 1em;
  }

  .Page > .PageHeaderOutline {
    padding-left: 1em;
    padding-right: 1em;
  }

  .Page > .Section {
    border-radius: 0px;
    padding: 1em 0em;
  }

  .Page > .Section .HeaderLine,
  .POsPage > .Section .ItemLine {
    padding-left: 1em;
    padding-right: 1em;
  }

}


.Page .StatTables {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 2em;
}

.Page .StatTables.MetricStats table {
  text-align: center;
  td {
    font-size: 1.5em;
  }
}
