.VendorForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "name"
    "website"
    "pocFirst"
    "pocLast"
    "street1"
    "street2"
    "city"
    "province"
    "zip"
    "country"
    "fax"
    "phone"
    "email"
    "description";
}

.VendorForm > .Name {
  grid-area: name;
}

.VendorForm > .Website {
  grid-area: website;
}

.VendorForm > .POCFirst {
  grid-area: pocFirst;
}

.VendorForm > .POCLast {
  grid-area: pocLast;
}

.VendorForm > .Street1 {
  grid-area: street1;
}

.VendorForm > .Street2 {
  grid-area: street2;
}

.VendorForm > .City {
  grid-area: city;
}

.VendorForm > .Province {
  grid-area: province;
}

.VendorForm > .Zip {
  grid-area: zip;
}

.VendorForm > .Country {
  grid-area: country;
}

.VendorForm > .Fax {
  grid-area: fax;
}

.VendorForm > .Phone {
  grid-area: phone;
}

.VendorForm > .Email {
  grid-area: email;
}

.VendorForm > .Description {
  grid-area: description;
}

.Section[data-size="medium"] {
  .VendorForm {
    grid-template-columns: 1fr 1fr;
    grid-template-areas:
      "name     ."
      "website  ."
      "pocFirst pocLast"
      "street1  street1"
      "street2  street2"
      "city     province"
      "zip      country"
      "fax      phone"
      "email    email"
      "description description";
  }
}

.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .VendorForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
      "name     name      .         ."
      "pocFirst pocLast   website   website"
      "street1  street1   street1   street1"
      "street2  street2   street2   street2"
      "city     city      province  zip"
      ".        .         country   country"
      "fax      phone     email     email"
      "description description description description";
  }
}

.VendorForm[data-layout="popup"] {

  grid-template-areas:
    "name"
    "website"
    "pocFirst"
    "pocLast"
    "email"
    "description";

  > .Street1,
  > .Street2,
  > .City,
  > .Province,
  > .Zip,
  > .Country,
  > .Fax,
  > .Phone {
    display: none;
  }
}
