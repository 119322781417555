.CollectionForm {
  position: relative;
  grid-template-columns: 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Name"
    "Code"
    "Description";
}

.CollectionForm > .Name {
  grid-area: Name;
}

.CollectionForm > .Code {
  grid-area: Code;
}

.CollectionForm > .Description {
  grid-area: Description;
}
