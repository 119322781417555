.Select {
  font-size: 1em;
  position: relative;
  min-width: 6em;
  text-align: left;
  display: inline-block;
}

.Select[data-focused="true"] {
  /* border-color: #999; */
  /* border-color: #0070c9; */
}

.Select[data-labeled="true"] {
  height: 3em;
}

.Select > .Field {
  position: relative;
  width: 100%;
  background-color: transparent;
  border: none;
  padding: 0px;
  font-size: 1em;
  transition: all 0.1s;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0px;
}

.Select[data-labeled="true"] > .Field {
  border-radius: 5px;
  padding-left: 0.5em;
  padding-top: 1.5em;
  padding-bottom: 0.5em;
  background-color: rgba(0,0,0,0.05);
  box-sizing: border-box;
}

.Select[data-clearable="true"] > .Field {
  padding-right: 2em;
  width: calc(100% - 3em);
}

.Select > .Label {
  position: absolute;
  top: 0.5em;
  left: 0.5em;
  transform: none;
  text-align: left;
  pointer-events: none;
  font-size: 0.75em;
  opacity: 0.75;
}

.Select[data-focused="true"]  > .Label {
  color: #006095;
  font-weight: bold;
}

.Select[data-labeled="true"][data-focused="true"] > .Field {
  background-color: rgba(0,85,144,0.05);
}

.Select[data-lock="true"] {
  pointer-events: none;
}
.Select[data-lock="true"] > .Field {
  background-color: rgba(0,0,0,0.05);
  color: gray;
}

.Select[data-error="true"] > .Field {
  border-color: red;
}

.Select[data-error="true"] > .Label {
  color: red;
}
.Select[data-focused="false"][data-error="true"] > .Label {
  animation: Select-error-shake  infinite 2.5s;
}

/* Phone screens */
@media (max-width: 30.5em) {
  .Select {
    width: 100%;
  }
}

@keyframes Select-error-shake {
  0% {
    left: 1em;
  }
  20% {
    left: 0.85em;
  }
  50% {
    left: 1.5em;
  }
  75% {
    left: 0.75em;
  }
  100% {
    left: 1em;
  }
}
