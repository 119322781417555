.TabControl {
  position: relative;
  width: 100%;
  display: inline-block;
  text-align: left;
  overflow-x: scroll;
  white-space: nowrap;
  vertical-align:top;
}

.TabControl .Options > .Option {
  display: inline-block;
  box-sizing: border-box;
  white-space: nowrap;
  padding-left:1em;
  padding-right:1em;
  padding-top:0.25em;
  padding-bottom:0.25em;
  color: silver;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  background-color: rgba(255,255,255,0.5);
  font-weight: bold;
}

.TabControl .Options > .Option:first-child {
  border-top-left-radius: 5px;
  border-left: 1px solid transparent;
  /* padding-left:0px; */
}
.TabControl .Options > .Option:last-child {
  border-right: 1px solid transparent;
  border-top-right-radius: 5px;
}


.TabControl .Options > .Option:hover {
  background-color: rgba(255,255,255,0.75);
  color: #333;
}

.TabControl .Options > .Option[data-selected="true"] {
  color: #333;
  background-color: white;
  border-bottom: 1px solid white;
  /* box-shadow: 3px -5px 11px 0px #eee; */
}

/* .TabControl .Options > .Option[data-selected="true"]:last-child {
  border-right: 1px solid #bbb;
} */
