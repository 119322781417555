.TableSearchSection {

  .QuerySummaryLine[data-filtered="true"] {
    margin-bottom: 0.5em;
  }

  table.Results {

    thead {
      z-index: 5;
    }

    th:first-child,
    td:first-child {
      padding-left: 1.5em;
    }
    th:last-child,
    td:last-child {
      padding-right: 1.5em;
    }

    .Controls {
      position: relative;
      width: 1em;
      white-space: nowrap;
      text-align: center;
      font-size: 0.9em;
    }
    .Controls:hover {
      cursor:pointer;
    }

    .Check {
      text-align: center;
      padding-left: 0.25em;
      padding-right: 0.25em;

      .FloatDetails > div {
        left: 75%;
      }
    }

    .Icon {
      width: 2em;
      text-align: center;
      padding-left: 0;
      padding-right: 0;
      img {
        display: block;
        display: block;
        height: 2em;
        width: 2em;
        object-fit: contain;
      }
      img[data-blank="true"] {
        box-sizing: border-box;
        // padding: 0.45em;
        opacity: 0.15;
      }
    }

    tr[data-creating="true"] td.Controls {
      opacity: 0.5;
      color: red;
    }

    tr[data-dependent="true"] {
      .Controls::after {
        content: " ";
        display: block;
        position: absolute;
        top: 50%;
        left: 0px;
        width: 5px;
        height: 15px;
        background-color: #55a1605c;
        border-bottom-right-radius: 5px;
        border-top-right-radius: 5px;
        transform: translateY(-50%);
      }
    }

    tr[data-working="true"] {
      > td {
        pointer-events: inherit;
      }
    }

    // tr select {
    //   background-color: transparent;
    //   border-width: 1px;
    //   border: 1px solid;
    //   box-sizing: border-box;
    //   padding: 0.1em;
    // }
  }


}
