@import url('https://fonts.googleapis.com/css?family=Roboto');
@import url('https://fonts.googleapis.com/css?family=Roboto+Condensed:700');
@import url('https://fonts.googleapis.com/css?family=Roboto:100');

body {
  font-family: 'Roboto';
  /*background: rgba(0,0,0,0.80);*/
  /* background: #292c34; */
  background: #222;
  background: #F8F8F8;
  /* color: grey; */
  /* color: #abb2bf;  */
  color: #333;
  /*font-size: 1.25em;*/
  font-size: 1em;
}


/* clears the 'X' from Internet Explorer */
input[type=search]::-ms-clear {  display: none; width : 0; height: 0; display: none; }
input[type=search]::-ms-reveal {  display: none; width : 0; height: 0; display: none; }

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration { display: none; }

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

/* Input general stylings */
input:focus {
  outline-width: 0;
}
input::placeholder {
  color: silver;
}


.App {
  text-align: center;
}

.TopSpace {
  display: inline-block;
  width: 100%;
  height: 3em;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.Frame {
  position: relative;
}

.Ellipsify {
  white-space: nowrap;
  overflow-y: hidden;
  text-overflow: ellipsis;
}

/*
 Color Styles
*/

.App[data-theme="dark"]  {

}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsing {
  0% {
    transform: scale(1);
  }
  33% {
    transform: scale(0.95);
  }
  66% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes bouncing {
  0% {
    transform: translateY(0%);
  }
  30% {
    transform: translateY(-40%);
  }
  45% {
    transform: translateY(0%);
  }
  55% {
    transform: translateY(-20%);
  }
  65% {
    transform: translateY(0%);
  }
  75% {
    transform: translateY(-10%);
  }
  85% {
    transform: translateY(0%);
  }
  85% {
    transform: translateY(0%);
  }
  95% {
    transform: translateY(0%);
  }
}

a.loading {
  position: relative;
  color: #881381 !important;
  pointer-events: none;
}

a.loading svg {
  animation: bouncing infinite 1s linear;
}

a.loading::after {
  content: attr(data-progress);
  margin-left: 0.5em;
  font-weight: bold;
}