.LeftMenu {
  position: fixed;
  width: 15em;
  height:100vh;
  top: 0;
  left: 0;
  /* background-color: rgba(0,0,0,0.5); */
  z-index: 90;
  display: inline-block;
  transition: left 0.35s ease-out;
}

.LeftMenu > .Content {
  position: relative;
  padding: 1em 0;
  height: 100%;
  width: 100%;
  max-width: 16em;
  box-sizing: border-box;
  /* border-right: 1px solid #ddd;
  background-color: #F8F8F8; */
  background-color: #f8f8f8;
  box-shadow: -1px 0px 6px rgb(0 0 0 / 20%);
}

.LeftMenu > .Content > .MenuTop {
  height: 2.2em;
  /* border-bottom: 1px solid silver; */
  margin-bottom: 1em;
}

.LeftMenu > .Content > .MenuBottom {
  position: absolute;
  bottom: 0;
  left: 0;
  padding: 1em;
}

.LeftMenu > .Content > .MenuBottom > .AppVersion {
  font-size: 0.8em;
  opacity: 0.6;
}

.LeftMenu > .Content > .Controls {
  position: absolute;
  top:0;
  left: -1em;
  background-color: transparent;
  width: calc(100% + 3.5em);
  text-align: right;
}

.LeftMenu > .Content > .Controls > .PlainButton {
  right: -2.5em;
  height: 2em;
  font-size: 2em;
  line-height: 2em;
  color: white;
}

.LeftMenu > .Content .Header {
  font-size: 0.8em;
  font-weight: bold;
  padding-top: 2em;
  padding-left: 1.3em;
  padding-bottom: 0.5em;
  opacity: 0.5;
}

/* Small screens */
@media (max-width: 60.5em) {
  .LeftMenu {
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    background-color: rgba(0,0,0,0.5);
    z-index: 15;
  }

  .LeftMenu > .Content {
    position: relative;
    background-color: white;
    height: 100%;
    box-sizing: border-box;
  }
}

.LeftMenu[data-expanded="false"] {
  left: -16em;
  transition: left 0.5s;
}
