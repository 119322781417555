.ReturnsPage {
  display: inline-block;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
}

.ReturnsPage table.Results {
  thead {
    z-index: 5;
  }

  .Controls {
    min-width: 1em;
    white-space: nowrap;
  }
  .Created {
    min-width: 7em;
    white-space: nowrap;
  }
  .RefName {
    min-width: 10em;
    white-space: nowrap;
  }
  .Origin {
    white-space: nowrap;
    min-width: 5em;
  }
  .Order {
    white-space: nowrap;
    min-width: 5em;
  }
  .Product {
    white-space: nowrap;
    min-width: 8em;
  }
  .Quantity {
    min-width: 3em;
    input { text-align: center; }
  }

  .ReturnAmount,
  .DeductAmount {
    white-space: nowrap;
    input {
      text-align: right;
    }
  }
  .Reason {
    white-space: nowrap;
  }
  .Description,
  .Tracking {
    min-width: 10em;
  }

  .Completed {
    min-width: 7em;
    white-space: nowrap;
  }

  .Controls,
  .Notes {
    text-align: center;
    .FloatDetails > div {
      text-align: left;
    }
  }

  .Notes {
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }

}

.ReturnsPage table.Results[data-sticky="true"] {
  .Controls,
  .RefName,
  .Order,
  .Product,
  .Quantity
  {
    position: sticky;
  }

  .Controls { left: 0; }
  .RefName { left: 2.5em; }
  .Order { left: 14em; }
  .Product { left: 20.5em; }
  .Quantity { left: 30em; }

  tr[data-pinned="true"] {
    .Controls { z-index: 3; }
    .Order { z-index: 2;}
    .Product { z-index: 1; }
  }
}

.ReturnsPage[data-view="support"] table.Results {
  .Restocked {
    display: none;
  }
}

.ReturnsPage[data-view="shipping"] table.Results {
  .Reason,
  .Deadline,
  .Description,
  .Carrier,
  .Tracking,
  .Resolution,
  .Status,
  .Completed,
  .ReturnAmount,
  .DeductAmount {
    display: none;
  }
}

.ReturnsPage .Results .OrderInfo {
  display: flex;
  justify-content: space-between;
  .StoreIcon {
    color: rgb(150, 191, 72);
    margin-left: 1em;
  }
}

.ReturnsPage .Results tr[data-deleted="true"] {
  text-decoration: line-through !important;
  input,
  select {
    pointer-events: none;
    text-decoration: line-through;
  }
  button {
    display: none;
  }
}

.ReturnsPage .StatTables {
  display: flex;
  gap: 1.5em;
  flex-wrap: wrap;
  align-items: baseline;
  margin-bottom: 2em;
}

.ReturnsPage .StatTables.MetricStats {
  table {
    min-width: 7em;
    text-align: center;
    td {
      font-size: 1.5em;
    }
  }
}

.ReturnsPage .NarrowStats {
  flex-flow: column;
}
