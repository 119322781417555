.CheckBoxOption {

}

.CheckBoxOption > .Check,
.CheckBoxOption > .Entry {
  display: inline-block;
}

.CheckBoxOption > .Check {
  margin-right: 1em;
}

.CheckBoxOption[data-selected="true"] {
  background-color: #00609515;
  color: #006095;
}