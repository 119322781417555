.SheetLayout {
  display: table-row;
  // position: relative;
  width: 100%;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
  border-bottom: 1px solid silver;
}

.SheetLayout[data-clickable="true"]:hover {
  background-color: rgba(0, 0, 0, 0.05);
}

.SheetLayout > .Value {
  display: table-cell;
  vertical-align: middle;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  min-width: 7em;
}

.SheetLayout > .Value:first-child {
  min-width: 10em;
  border-left: none;
}

.SheetLayout[data-creating="true"] {
  background-color: rgba(19, 136, 139, 0.03);
  box-shadow: inset 0 0 5px rgba(19,136,139,0.1);
}

.SheetLayout[data-working="true"] {
  background-color: rgba(136,19,139,0);
  animation: SheetLayout-working infinite 1.5s;
}

@keyframes SheetLayout-working {
  0% {
    background-color: rgba(136,19,139,0);
  }
  20% {
    background-color: rgba(136,19,139,0.20);
  }
  50% {
    background-color: rgba(136,19,139,0.05);
  }
  75% {
    background-color: rgba(136,19,139,0.10);
  }
  100% {
    background-color: rgba(136,19,139,0);
  }
}
