.Line {
  width: 100%;
  /* color: #333; */
}

/* .Line:hover {
  background-image: linear-gradient(90deg, rgba(255, 255, 255, .09) 0%, rgba(0, 0, 0, 0.05) 50%,rgba(255, 255, 255, .09) 100%);
  cursor: pointer;
} */

.Line > div:first-child {
  text-align: left;
}

.Line > div:last-child {
  text-align: right;
}

.Line > div > .Highlight {
  display: inline-block;
  min-width: 5em;
  border: 1px solid #ccc;
  border-radius: 10px;
  background-color: #eee;
  color: rgba(0,0,0,0.75);
  padding-left: 0.25em;
  padding-right: 0.25em;
  font-size: 0.75em;
  opacity: 0.85;
  text-align: center;
}
