.QueryLine {
  display: grid;
  position: relative;
  width: 100%;

  grid-template-columns: 1em 3fr 2em;
  grid-template-rows: auto;
  grid-template-areas: "Icon Input Sort";
  grid-column-gap: 0;
  align-items: center;

  border: 1px solid #dddddd;
  border-radius: 5px;
  padding: 0em;
  box-sizing: border-box;
}

.QueryLine[data-filters="1"] {
  grid-template-columns: 1em 3fr 10em 2em;
  grid-template-areas: "Icon Input Filter0 Sort";
}

.QueryLine[data-filters="2"] {
  grid-template-columns: 1em 3fr 10em 10em 2em;
  grid-template-areas: "Icon Input Filter0 Filter1 Sort";
}

.QueryLine > .Icon {
  grid-area: Icon;
  font-size: 1em;
  padding-left: 0.5em;
  opacity: 0.25;
}

.QueryLine > .Input {
  grid-area: Input;
  line-height: 2.1em;
  padding: 0em 1em;
  width: 100%;
  box-sizing: border-box;
}

.QuerySummaryLine .ClearableVal .DateRangeInput {
  display: flex;
  gap: 1em;
  input::-webkit-calendar-picker-indicator{
    margin-left: -15px;
  }
}

.QuerySummaryLine  .ClearableVal  .DateRangeInput[data-dirty=true] {
  color: #881381;

}

.QueryLine > .FilterButton {
  height: 2.3em;
  line-height: 2.3em;
  display: none;
}

.QueryLine > .Setting,
.QueryLine > .Filter {
  box-sizing: border-box;
  border-left: 1px solid #dddddd;
  width: 100%;
  // background-color: white;
}

.QueryLine > .Filter0 {
  grid-area: Filter0;
}

.QueryLine > .Filter1 {
  grid-area: Filter1;
}

.QueryLine > .Sort {
  border-right: 5px;
  grid-area: Sort;
  width: 100%;
}

.QueryLine[data-filters="3"],
.QueryLine[data-filters="4"],
.QueryLine[data-filters="5"],
.QueryLine[data-filters="6"],
.QueryLine[data-filters="7"] {
  grid-template-columns: 1em 3fr 10em 2em 2em;
  grid-template-areas: "Icon Input Filter0 FilterButton Sort";

  .Filter1,
  .Filter2,
  .Filter3,
  .Filter4,
  .Filter5,
  .Filter6,
  .Filter7 {
    display: none;
  }

  .FilterButton {
    display: block;
  }
}

.Section[data-size="xsmall"],
.Section[data-size="small"]   {

  .QueryLine {
    grid-template-columns: 1em 1fr 2em 2em;
    grid-template-areas: "Icon Input FilterButton Sort";

    .FilterButton {
      display: block;
    }

    .Filter {
      display: none;
    }
  }
}
