.OptionLayout {
  display: grid;
  position: relative;
  width: 100%;
  grid-template-rows: auto;
  grid-template-columns: 1em 2em 1fr 0.5em;
  grid-template-areas: "Check Image Content Config";
  grid-gap: 0.5em;
  align-items: center;
  padding: 0em;
  box-sizing: border-box;
}

.OptionLayout:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.OptionLayout[data-selected="true"]  {
  background-color: #00609515;
}

.OptionLayout > .Check  {
  color: #00609577;
  text-align: left;
  grid-area: Check;
}

.OptionLayout > .Image  {
  text-align: center;
  align-self: center;
  grid-area: Image;
}

.OptionLayout > .Content  {
  grid-area: Content;
}

.OptionLayout > .Config  {
  grid-area: Config;
}

.OptionLayout[data-selected="true"]  > .Check  {
  color: #006095;
}

.OptionLayout > .Image > img {
  max-width: 100%;
  object-fit: contain;
}

.OptionLayout[data-pinned="true"]  > .Check  {
  display: none;
}

.OptionLayout[data-creating="true"] {
  background-color: #f7fef8;
  box-shadow: inset 0 0 5px rgba(0,248,92,0.2);
}

.OptionLayout[data-status="creating"],
.OptionLayout[data-status="saving"],
.OptionLayout[data-status="deleting"]   {
  background-color: white;
  animation: tr-working infinite 1.5s;
  pointer-events: none;
  box-shadow: none;
}
