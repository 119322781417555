.StubsPage {
  display: inline-block;
  margin-bottom: 4em;
  width: 100%;
  box-sizing: border-box;
}

.StubsPage {
  > .Results {
    .Stub { min-width: 6em; }
    .State { min-width: 5em; }
    .Number { min-width: 5em; }
    .Email { width: 15em; }
  }
}
