.Dashboard {

  table {
    //width: 100%;
    gap: 5px;
    background-color: transparent;
    border-spacing: 2em 2em;
    //table-layout: fixed;
    th,
    td {
      background-color: transparent;
    }
    .Category {
      font-size: 1.25em;
      text-align: right;
      width: 5em;
    }
  }

  .Sub {
    font-weight: normal;
    padding-top: 1em;
    opacity: 0.5;
  }

  .RevCard {
    background-color: white;
    box-shadow: 0 2px 3px rgba(0,0,0,.1);
    border-radius: 5px;
    display: grid;
    grid-template-columns: 1fr 1.25fr 1.25fr;
    grid-gap: 0;
    row-gap: 0.5em;
    padding: 1em;
    grid-template-rows: auto;
    min-width: 10em;
    grid-template-areas:
      "Overall Last Current";

    &[treand=negative] {
      background-color: rgba(255,240,240,1);
    }


    &[animate=loading] {
      animation: animation-fading infinite 2s linear;
      pointer-events:none;
      user-select: none;
    }
    &[blank=true][animate=loading] {
      > div {
        background-color: silver !important;
        color: transparent !important;
        opacity: 0.2;
      }
    }

    .Overall {
      grid-area: Overall;
      font-size: 1.5em;
      min-height: 1.5em;
    }

    .Change[trend="positive"] { color: green; }
    .Change[trend="negative"] { color: red; }

    .UChange[trend="positive"] { color: green; }
    .UChange[trend="negative"] { color: red; }
    

    .UChange[trend="flat"],
    .Change[trend="flat"] {
      align-content: center;
      &:after {
        content: "";
      }
    }

    .UChange {
      font-weight: 400;
      &:after {
        content: "%";
      }
    }

    .Change {
      font-size: 1.5em;
      font-weight: 600;
      color: silver;
      &:after {
        content: "%";
      }
    }

    .Last {
      grid-area: Last;
      opacity: 0.5;
      align-content: center;
    }
    .Current {
      grid-area: Current;
      border-left: 1px solid rgba(0,0,0,0.1);
      align-content: center;
    }

    .Revenue {
      margin-bottom: 0.75em;
    }

  }

}
