.VendorItemForm {
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-gap: 1.25em;
  grid-template-rows: auto;
  grid-template-areas:
    "Vendor Vendor"
    "Product Product"
    "URL URL"
    "LeadTime Price";
}

.VendorItemForm > .Vendor {
  grid-area: Vendor;
}

.VendorItemForm > .Product {
  grid-area: Product;
}

.VendorItemForm > .URL {
  grid-area: URL;
}

.VendorItemForm > .LeadTime {
  grid-area: LeadTime;
}

.VendorItemForm > .Price {
  grid-area: Price;
}
