.LoadableArea {

  > .Empty {
    text-align: center;
    > .Icon {
      font-size: 4em;
      // color: #caecff;
      // color: #f44336;
      color: #ffc107;
    }
  }

  > .Indicator {
    position: absolute;
    top: calc(50% - 35px);
    left: calc(50% - 35px);
    height: 70px;
    width: 70px;
    border-radius: 50%;
    font-size: 3em;
    border: solid 5px transparent;
    // border-left-color: #6774c8;
    border-left-color: #006095;
    animation: loadable-area-indicator-spin infinite 0.85s linear;
    box-sizing: border-box;
    display: none;
  }
}


.LoadableArea[data-working="true"] {
  min-height: 10em;
  overflow: hidden;

  > div {
    opacity: 0.25;
  }

  > .Indicator {
    display: block;
    opacity: 1;
  }

  > .Empty {
    display: none;
  }

}




@keyframes loadable-area-indicator-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
