.Page.BuildPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.BuildPage > * {
  max-width: 65em;
  width: 100%;
}

.BuildPage {

  .QuerySummaryLine {
    margin-bottom: 0.5em;
  }

  .Results {
    width: 100%;
  }
}


.BuildPage table.Results {

  thead {
    z-index: 5;
  }

  .Controls {
    position: relative;
    width: 1em;
    white-space: nowrap;
    text-align: center;
    font-size: 0.9em;
  }
  .Controls:hover {
    cursor:pointer;
  }

  .Check {
    text-align: center;
    padding-left: 0.25em;
    padding-right: 0.25em;
  }

  .Icon {
    width: 2em;
    text-align: center;
    padding-left: 0;
    padding-right: 0;
  }

  .Stock {
    width: 5em;
    text-align: right;
  }

  .Quantity {
    width: 5em;
    text-align: right;
  }

  .PO {
    text-align: center;
  }



  tr[data-creating="true"] td.Controls {
    opacity: 0.5;
    color: red;
  }

  tr[data-dependent="true"] {
    .Controls::after {
      content: " ";
      display: block;
      position: absolute;
      top: 50%;
      left: 0px;
      width: 5px;
      height: 15px;
      background-color: #55a1605c;
      border-bottom-right-radius: 5px;
      border-top-right-radius: 5px;
      transform: translateY(-50%);
    }
  }
}
