.POForm {
  position: relative;
  grid-template-rows: auto;
}

.POForm > .State {
  grid-area: state;
}

.POForm > .Fulfillment {
  grid-area: fulfillment;
}

.POForm > .Name {
  grid-area: name;
}

.POForm > .Vendor {
  grid-area: vendor;
}

.POForm > .Description {
  grid-area: description;
}

.POForm > .DepositedAt {
  grid-area: depositedAt;
}

.POForm > .Deposit {
  grid-area: deposit;
}

.POForm > .PaidAt {
  grid-area: paidAt;
}

.POForm > .Expected {
  grid-area: expected;
}

.POForm > .Paid {
  grid-area: paid;
}

.POForm > .Owed {
  grid-area: owed;
}

.POForm > .Total {
  grid-area: total;
}

.Section[data-size="xsmall"],
.Section[data-size="small"] {
  .POForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "state    state   fulfillment fulfillment"
    "name     name    name    name"
    "vendor   vendor  vendor  vendor"
    "description   description  description  description"
    "expected   expected  expected  expected"
    "total  total paid  paid"
    ".      .     owed  owed";
  }
}


.Section[data-size="medium"] {
  .POForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "state    fulfillment       .       ."
    "name     name    name    name"
    "vendor   vendor  vendor  vendor"
    "description   description  description  description"
    "expected expected  total paid"
    ".        .         .     owed";
  }
}

.Section[data-size="large"],
.Section[data-size="xlarge"],
.Section[data-size="xxlarge"] {
  .POForm {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas:
    "state    fulfillment       .       ."
    "name     name    name    name"
    "vendor   vendor  vendor  vendor"
    "description   description  description  description"
    "expected        total    paid    owed";
  }
}
