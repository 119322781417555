.Page.CollectionsPage {
  max-width: 934px;
  display: flex;
  flex-flow: column;
  align-items: center;
}

.Page.CollectionsPage > div {
  max-width: 65em;
  width: 100%;
}

.CollectionsPage > .TableSearchSection {
  .ID {
    width: 3em;
    text-align: right;
  }

  .Name {
    width: 100%;
  }

  .Code {
    width: 5em;
  }

  .Description {
    width: 1em;
    .FloatDetails > div {
      right: 0;
      left: unset;
    }
  }
}
