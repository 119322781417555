.ProductLayout {
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-gap: 0.5em;
  grid-template-areas:
    "type  sku "
    "name  name";
}

.ProductLayout > .Name {
  grid-area: name;
  text-align: left;
}

.ProductLayout > .Type {
  grid-area: type;
  align-self: center;
  font-size: 0.8em;
}

.ProductLayout > .Sku {
  text-align: left;
  grid-area: sku;
  font-size: 0.8em;
}

.ProductLayout > .Image > img {
  max-width: 100%;
  object-fit: contain;
}
