.POItemPopup {
  width: 100%;
  height: 100%;
  padding: 1em;
  box-sizing: border-box;

  /* Possible fixes for safari touch scrolling */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.POItemPopup > .Section {
  margin-bottom: 1em;
}
